import React, { FC } from 'react'
import HeaderFiltersMobile from './HeaderFiltersMobile'
import CustomFiltersMobile from './CustomFiltersMobile'
import { ProcessTableHeaders } from '../table/TableProcess'
import HeaderSearchSearchMobile from './HeaderSearchSearchMobile'
import { Button } from '@sistemiv/s-components'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

type ProcessInstancesFiltersMobileProps = {
  headers: ProcessTableHeaders[]
  onSearch?: (field: string, operation: string, value: any) => void
  filters: string[]
  headerFilters: any
  showDone: boolean
  setShowDone: () => void
}

const ProcessInstancesFiltersMobile: FC<ProcessInstancesFiltersMobileProps> = ({
  headers,
  onSearch,
  filters,
  headerFilters,
  showDone,
  setShowDone,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className='w-full flex items-center px-3 z-0'>
        <HeaderSearchSearchMobile onSearch={onSearch} value={headerFilters?.['processInstanceCode|name|description']} />
      </div>
      <HeaderFiltersMobile headers={headers} onSearch={onSearch} filters={filters} headerFilters={headerFilters} />
      <div className='flex justify-between items-center'>
        <CustomFiltersMobile />
        <Button className=' !gap-x-0' onClick={setShowDone}>
          {showDone ? <EyeIcon className='w-5 h-5' /> : <EyeSlashIcon className='w-5 h-5' />}
          {t('Common.done')}
        </Button>
      </div>
    </>
  )
}

export default ProcessInstancesFiltersMobile
