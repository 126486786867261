import React, { FC, useState } from 'react'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { Combobox } from '@headlessui/react'
import classNames from '../../../classNames'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { DataField, TabPanel, Tabs } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import AssigneeSearchMobile from './AssigneeSearchMobile'

type AssigneeComboboxBulkActionProps = {
  options: TableField[]
  selected?: TableField
  onChange?: (assignee: any) => void
}

const AssigneeComboboxBulkAction: FC<AssigneeComboboxBulkActionProps> = ({ options, selected, onChange }) => {
  const [openSearch, setOpenSearch] = useState<boolean>(false)
  const { t } = useTranslation()
  const tabs = ['Users', 'Groups', 'Org chart']
  const [selectedTab, setSelectedTab] = useState(selected?.tab ?? 'Users')
  const filteredOptions = options

  return (
    <>
      {openSearch && (
        <AssigneeSearchMobile
          options={options}
          value={[]}
          singleValue={selected}
          onChangeSingle={onChange}
          setIsOpen={setOpenSearch}
          single={true}
        />
      )}
      <div className='max-h-60 overflow-y-auto'>
        <Combobox
          onChange={(value) => {
            if (onChange) {
              onChange(value)
            }
          }}
        >
          <div className='w-full max-h-full overflow-hidden'>
            <Combobox.Options static={true}>
              <div className='w-full relative border-b border-gray-300'>
                <div className='w-full flex items-center px-3 z-0 cursor-pointer' onClick={() => setOpenSearch(true)}>
                  <div className='relative w-full py-2'>
                    <span className='box-border pr-3 pl-8 py-2 w-full border-none focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-sky-100 rounded cursor-pointer text-gray-400'>
                      {t('Common.search')}
                    </span>
                    <span className='absolute inset-y-0 flex items-center pl-2 left-0'>
                      <MagnifyingGlassIcon className='w-4 h-4 text-gray-600 stroke-2' aria-label='true' />
                    </span>
                  </div>
                </div>
              </div>
              <Tabs active={selectedTab} tabs={tabs} onClick={setSelectedTab} className='px-3 [&+div]:!pt-2'>
                <TabPanel active={selectedTab} tab='Users'>
                  {filteredOptions
                    .filter((o) => o.tab === 'Users')
                    .map((o) => (
                      <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                        <div
                          className={classNames(
                            'flex items-center gap-x-3 py-2 px-3',
                            selected?.id === o.id ? 'opacity-100' : 'opacity-70',
                          )}
                        >
                          <DataField field={o} />
                        </div>
                      </Combobox.Option>
                    ))}
                </TabPanel>
                <TabPanel active={selectedTab} tab='Groups'>
                  {filteredOptions
                    .filter((o) => o.tab === 'Groups')
                    .map((o) => (
                      <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                        <div
                          className={classNames(
                            'flex items-center gap-x-3 py-2 px-3',
                            selected?.id === o.id ? 'opacity-100' : 'opacity-70',
                          )}
                        >
                          <DataField field={o} className='[&>div>svg]:w-5 [&>div>svg]:h-5' />
                        </div>
                      </Combobox.Option>
                    ))}
                </TabPanel>
                <TabPanel active={selectedTab} tab='Org chart'>
                  {filteredOptions
                    .filter((o) => o.tab === 'Org chart')
                    .map((o) => (
                      <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                        <div
                          className={classNames(
                            'flex items-center gap-x-3 py-2 px-3',
                            selected?.id === o.id ? 'opacity-100' : 'opacity-70',
                          )}
                        >
                          <DataField field={o} />
                        </div>
                      </Combobox.Option>
                    ))}
                </TabPanel>
              </Tabs>
            </Combobox.Options>
          </div>
        </Combobox>
      </div>
    </>
  )
}

export default AssigneeComboboxBulkAction
