import { DatePicker, Option, Select } from '@sistemiv/s-components'
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFilteredPriorities, Priority } from '../../../utils/priorityUtils'
import { NodeValue } from './CreateProcessInstanceModal'
import UserService from '../../../services/UserService'
import { useParams } from 'react-router-dom'
import { PriorityIcon } from '@sistemiv/s-components/dist/esm/components/input/Input'

type CreateProcessInstanceDefaultFormProps = {
  selectProcess?: boolean
}

const CreateProcessInstanceDefaultForm = forwardRef<unknown, CreateProcessInstanceDefaultFormProps>(
  ({ selectProcess }, ref) => {
    const now = useMemo(() => new Date(), [])
    const { org } = useParams()
    const [dueDate, setDueDate] = useState<Date | null>(null)
    const [notes, setNotes] = useState('')
    const [priority, setPriority] = useState<Priority>({ label: 'Medium', value: 50 })
    const [organizationNodes, setOrganizationNodes] = useState<NodeValue[]>([])
    const [selectedNode, setSelectedNode] = useState<NodeValue>()
    const { t } = useTranslation()

    console.log(selectProcess)

    const priorities = getFilteredPriorities(priority.value)

    const handleChange = (e) => {
      setNotes(e.target.value)
    }

    useEffect(() => {
      if (!org) return
      UserService.getMyOrgNode(org).then((res) => {
        setSelectedNode(res)
      })
      UserService.listAllOrgNodes(org).then((res) => {
        setOrganizationNodes(res.results)
      })
    }, [org])

    useImperativeHandle(ref, () => ({
      getState: () => {
        return { node: selectedNode, priority: priority, dueDate: dueDate, note: notes }
      },
    }))

    return (
      <>
        <div className='mt-3'>
          <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.orgNode')}</p>
          <Select
            disabled={organizationNodes?.length === 0 || (!!selectedNode && organizationNodes?.length === 1)}
            className='border-gray-400 !py-1.5 !rounded'
            value={selectedNode}
            displayValue={selectedNode?.name}
            onChange={(v) => setSelectedNode(v)}
          >
            {organizationNodes
              ?.filter((node) => node?.name !== selectedNode?.name)
              .map((node) => (
                <Option value={node} key={node?.id}>
                  {node?.name}
                </Option>
              ))}
          </Select>
        </div>
        <div className='mt-3'>
          <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.dueDate')}</p>
          <DatePicker className='border border-gray-400 rounded' value={dueDate} onChange={setDueDate} min={now} />
        </div>
        <div className='mt-3'>
          <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.note')}</p>
          <textarea
            onChange={handleChange}
            value={notes}
            style={{ resize: 'none' }}
            className='text-sm py-2 px-3 border border-gray-400 w-full focus:outline-none focus:ring-2 focus:ring-sky-100 rounded-md'
          />
        </div>
        <div className='mt-3'>
          <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.priority')}</p>
          <Select
            type='priority'
            value={priority}
            onChange={(e) => {
              setPriority(e)
            }}
          >
            {priorities
              .filter((p) => p.value !== priority.value)
              .map((p) => (
                <Option key={p.value} value={p}>
                  <div className='flex justify-start items-center gap-x-3'>
                    <PriorityIcon priority={p.label} />
                    {p.label}
                  </div>
                </Option>
              ))}
          </Select>
        </div>
      </>
    )
  },
)

CreateProcessInstanceDefaultForm.displayName = 'CreateProcessInstanceDefaultForm'

export default CreateProcessInstanceDefaultForm
