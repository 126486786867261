import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessDefinitionsSettings from '../../../../../services/ProcessDefinitionsSettings'

export const useChangeUserAccess = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, { userId: string; itemId: string; processAccessId: string; accessLevel: string }>({
    mutationKey: ['add-user-access'],
    mutationFn: (payload) =>
      ProcessDefinitionsSettings.changeUserAccess(
        org!,
        payload.userId,
        payload.itemId,
        payload.processAccessId,
        payload.accessLevel,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['list-access-users'] })
    },
  })
}
