import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'
import ModalDialog from '../../ModalDialog'
import { Button, Option, Select, SpinnerIcon } from '@sistemiv/s-components'
import FormViewer from '../../../extensions/form/FormViewer'
import { useProcessInstanceCreate, useProcessStartFrom } from '../../../repositories'
import { ProcessDefinition, ProcessVersions } from '../../../models/ProcessDefinitionSettings'
import { useProcessVersions } from '../../../repositories/settings/processes/processes/process-versions.repository'
import { useParams } from 'react-router-dom'
import ProcessSchemasCombobox from '../../user-settings/processes-settings/process-shemas-combobox/ProcessSchemasCombobox'
import { useProcessFlat } from '../../../repositories/settings/processes/processes/process-flat.repository'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from '../../../context/ResponsiveContextProvider'
import CreateProcessInstanceDefaultForm from './CreateProcessInstaneDefaultForm'

type CreateProcessInstanceModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  selectProcess?: boolean
}

export type NodeValue = {
  id: string
  name: string
  path: string
}

const CreateProcessInstanceModal: FC<CreateProcessInstanceModalProps> = ({ open, setOpen, selectProcess }) => {
  const { org, type } = useParams()
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null)
  const formRef = useRef<any>(null)
  const defaultFormRef = useRef<any>(null)
  const [selectedVersion, setSelectedVersion] = useState<ProcessVersions | null>(null)
  const [selectedProcess, setSelectedProcess] = useState<ProcessDefinition | null>(null)
  const {
    data: startForm,
    isLoading,
    isFetching,
  } = useProcessStartFrom({
    version: selectedVersion?.versionNumber,
    type: selectProcess ? (selectedProcess ? selectedProcess.processDefinitionKey : undefined) : type,
  })
  const {
    data: processVersions,
    isLoading: isVersionsLoading,
    isFetching: isVersionsFetching,
  } = useProcessVersions({
    org,
    type: selectProcess ? (selectedProcess ? selectedProcess.processDefinitionKey : undefined) : type,
  })
  const { data: processSchemas, isLoading: isSchhemasLoading } = useProcessFlat({ org })
  const { mutate: createInstance, isPending } = useProcessInstanceCreate()
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  useEffect(() => {
    if (!processVersions) return
    const currentStartable = processVersions?.find((v) => v.current && v.startable) ?? null
    if (currentStartable) {
      setSelectedVersion(currentStartable)
    } else setSelectedVersion(processVersions?.find((v) => v.startable) ?? null)
  }, [processVersions])

  const handleCreate = (newData: any) => {
    const body = {}
    if (newData) {
      Object.keys(newData).forEach((oldKey) => {
        body[oldKey] = { value: newData[oldKey] }
      })
    }
    const defaultFormData = defaultFormRef.current.getState()
    createInstance(
      {
        processDefinitionKey: selectProcess ? selectedProcess?.processDefinitionKey ?? '' : type ?? '',
        version: selectedVersion?.versionNumber,
        dueDate: defaultFormData.dueDate ? defaultFormData.dueDate.toISOString() : '',
        notes: defaultFormData.note,
        priority: defaultFormData.priority.value,
        organizationalNode: defaultFormData.node ?? null,
        typedValues: Object.keys(body).length === 0 ? null : body,
      },
      {
        onSuccess: () => {
          setOpen(false)
        },
      },
    )
  }

  const desktopContent = (
    <ModalDialog isOpen={open} initialRef={cancelButtonRef}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('CreateProcessModal.newProcess')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      {isLoading || isSchhemasLoading || isVersionsLoading || isVersionsFetching ? (
        <div className='w-full flex items-center justify-center py-6'>
          <SpinnerIcon className='w-6 h-6 text-sky-500' />
        </div>
      ) : (
        <>
          {selectProcess && (
            <div className='mt-5 mb-5'>
              <p className='text-xs pb-1 text-gray-600'> {t('CreateProcessModal.process')}</p>
              <ProcessSchemasCombobox
                className='relative w-full cursor-default overflow-x-hidden bg-white text-left border rounded border-gray-400 py-1.5 text-base pl-3 pr-9'
                processSchemas={processSchemas}
                value={selectedProcess ?? undefined}
                onChange={(val) => setSelectedProcess(val)}
              />
            </div>
          )}
          {((selectProcess && selectedProcess) || !selectProcess) && (
            <div className='mt-5 mb-5'>
              <div>
                <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.version')}</p>
                <Select
                  disabled={
                    processVersions?.filter((pv) => pv.id !== selectedVersion?.id && pv.active && pv.startable)
                      ?.length === 0
                  }
                  className='border-gray-400 !py-1.5 !rounded'
                  value={selectedVersion}
                  displayValue={
                    selectedVersion ? `V.${selectedVersion?.versionNumber} ${selectedVersion?.versionTag}` : ''
                  }
                  onChange={(v) => setSelectedVersion(v)}
                >
                  {processVersions
                    ?.filter((pv) => pv.id !== selectedVersion?.id && pv.active && pv.startable)
                    .map((pv) => (
                      <Option value={pv} key={pv.id}>
                        V.{pv.versionNumber} {pv.versionTag}
                      </Option>
                    ))}
                </Select>
              </div>
              <CreateProcessInstanceDefaultForm ref={defaultFormRef} />
            </div>
          )}
          {startForm && startForm?.components?.length > 0 && (
            <FormViewer
              ref={formRef}
              schema={{
                ...startForm,
                components: startForm.components.map((f) => {
                  if (f.type.includes('file')) {
                    return {
                      ...f,
                      organization: org,
                    }
                  }
                  if (f.type.includes('dynamiclist')) {
                    return {
                      ...f,
                      components: f.components.map((component) => {
                        if (component.type.includes('file')) {
                          return {
                            ...component,
                            organization: org,
                          }
                        } else return component
                      }),
                    }
                  }
                  return f
                }),
              }}
              onSubmit={(data) => {
                handleCreate(data)
              }}
            />
          )}
        </>
      )}
      <div className='flex justify-end items-center gap-x-4' data-testid='create-process-buttons'>
        <Button className='text-slate-600' ref={cancelButtonRef} onClick={() => setOpen(false)}>
          {t('Common.cancel')}
        </Button>
        <Button
          className='bg-sky-500 text-white disabled:bg-slate-400'
          loading={isPending}
          tooltip={selectedVersion?.startable === false ? t('CreateProcessModal.notStartableMsg')! : ''}
          disabled={
            isFetching ||
            isPending ||
            isLoading ||
            isVersionsLoading ||
            isVersionsFetching ||
            (selectProcess && !selectedProcess) ||
            selectedVersion?.startable === false
          }
          onClick={() =>
            startForm && startForm?.components?.length > 0 ? formRef?.current?.submitForm() : handleCreate(null)
          }
        >
          {t('Common.create')}
        </Button>
      </div>
    </ModalDialog>
  )

  const mobileContent = (
    <div className='fixed inset-0 z-50 bg-white p-6 pb-20 overflow-auto'>
      <div className='flex justify-between items-center'>
        <h3 className='text-lg leading-6 flex-grow'>{t('CreateProcessModal.newProcess')}</h3>
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </div>

      {isLoading || isSchhemasLoading || isVersionsLoading || isVersionsFetching ? (
        <div className='w-full flex items-center justify-center py-6'>
          <SpinnerIcon className='w-6 h-6 text-sky-500' />
        </div>
      ) : (
        <>
          {selectProcess && (
            <div className='mt-5 mb-5'>
              <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.process')}</p>
              <ProcessSchemasCombobox
                className='relative w-full cursor-default overflow-x-hidden bg-white text-left border rounded border-gray-400 py-1.5 text-base pl-3 pr-9'
                processSchemas={processSchemas}
                value={selectedProcess ?? undefined}
                onChange={(val) => setSelectedProcess(val)}
              />
            </div>
          )}
          {((selectProcess && selectedProcess) || !selectProcess) && (
            <div className='mt-5 mb-5'>
              <div>
                <p className='text-xs pb-1 text-gray-600'>{t('CreateProcessModal.version')}</p>
                <Select
                  disabled={
                    processVersions?.filter((pv) => pv.id !== selectedVersion?.id && pv.active && pv.startable)
                      ?.length === 0
                  }
                  className='border-gray-400 !py-1.5 !rounded'
                  value={selectedVersion}
                  displayValue={
                    selectedVersion ? `V.${selectedVersion?.versionNumber} ${selectedVersion?.versionTag}` : ''
                  }
                  onChange={(v) => setSelectedVersion(v)}
                >
                  {processVersions
                    ?.filter((pv) => pv.id !== selectedVersion?.id && pv.active && pv.startable)
                    .map((pv) => (
                      <Option value={pv} key={pv.id}>
                        V.{pv.versionNumber} {pv.versionTag}
                      </Option>
                    ))}
                </Select>
              </div>
              <CreateProcessInstanceDefaultForm ref={defaultFormRef} />
            </div>
          )}
          {startForm && startForm?.components?.length > 0 && (
            <FormViewer
              ref={formRef}
              schema={{
                ...startForm,
                components: startForm.components.map((f) => {
                  if (f.type.includes('file')) {
                    return {
                      ...f,
                      organization: org,
                    }
                  }
                  if (f.type.includes('dynamiclist')) {
                    return {
                      ...f,
                      components: f.components.map((component) => {
                        if (component.type.includes('file')) {
                          return {
                            ...component,
                            organization: org,
                          }
                        } else return component
                      }),
                    }
                  }
                  return f
                }),
              }}
              onSubmit={(data) => {
                handleCreate(data)
              }}
            />
          )}
        </>
      )}

      <div className='fixed bottom-0 inset-x-0 bg-white p-4 flex justify-center gap-4 mb-4'>
        <button className='flex-1 h-9 text-gray-900 rounded-full' onClick={() => setOpen(false)}>
          {t('Common.cancel')}
        </button>
        <button
          className='flex-1 h-9 bg-sky-500 text-white rounded-full disabled:bg-gray-400 disabled:cursor-not-allowed'
          disabled={
            isFetching ||
            isPending ||
            isLoading ||
            isVersionsLoading ||
            isVersionsFetching ||
            (selectProcess && !selectedProcess) ||
            selectedVersion?.startable === false
          }
          onClick={() =>
            startForm && startForm?.components?.length > 0 ? formRef?.current?.submitForm() : handleCreate(null)
          }
        >
          {isFetching || isPending || isLoading || isVersionsLoading || isVersionsFetching ? (
            <SpinnerIcon className='w-3 h-3 text-gray-900 m-auto' />
          ) : (
            t('Common.create')
          )}
        </button>
      </div>
    </div>
  )

  if (isMobile) return mobileContent
  return desktopContent
}

export default CreateProcessInstanceModal
