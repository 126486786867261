import { Bars3Icon, GlobeAltIcon, ArrowRightIcon, CheckIcon } from '@heroicons/react/24/outline'
import {
  AppIcon,
  // CreateOrganizationModal,
  ImgWrapper,
  // JoinOrganizationModal,
  NavigationBar /*NotificationsMenuItem */,
  //SuccessNotification,
  MobileModalWithButton,
} from '@sistemiv/s-components'
import React, { FC, useEffect } from 'react'
import AppLogo from '../../assets/icons/AppLogo'
import { Menu, Transition } from '@headlessui/react'
import AppsIcon from '../../assets/icons/AppsIcon'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
//import { useProfile } from '../../repositories/user/user-profile.repository'
import { imageBase, portalUrl } from '../../services/http-common'
import { useGetApplications } from '../../repositories/user/user-applications.repository'
import { useOrganizations } from '../../repositories/user/use-organizations.repository'
import { useTranslation } from 'react-i18next'
import { useIsMobile, useNavigationContext } from '../../context/ResponsiveContextProvider'
// import { useCreateOrganization } from '../../repositories/organizations/mutations/create-organization.mutation'
// import { useJoinOrganization } from '../../repositories/organizations/mutations/join-organization.mutation'
// import { useAllOrganizations } from '../../repositories/organizations/organizations.respository'

const TopNav: FC<{ setSideOpen: DispatchSetAction<boolean> }> = ({ setSideOpen }) => {
  const { t, i18n } = useTranslation()
  const { org } = useParams()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { hideTopNavigation } = useNavigationContext()

  // const { accounts } = useMsal()
  // const userId = useMemo(() => {
  //   return accounts.at(0)?.localAccountId ?? undefined
  // }, [accounts])
  const { data: organizations } = useOrganizations()
  const { data: applications } = useGetApplications()
  // const [createOrganizationOpen, setCreateOrganizationOpen] = useState(false)
  // const [joinOrganizationOpen, setJoinOrganizationOpen] = useState(false)
  //const [notificationModalOpen, setNotificationModalOpen] = useState(false)
  //const [notificationMessage, setNotificationMessage] = useState('')
  //const [search, setSearch] = useState('')
  const { instance } = useMsal()
  // const { data: orgPages, fetchNextPage: fetchNextOrgs } = useAllOrganizations({
  //   userId: instance.getActiveAccount()?.localAccountId,
  //   search: search,
  // })
  // const { mutate: createOrganization, isPending: isCreating } = useCreateOrganization()
  // const { mutate: joinOrganization, isPending: isJoining } = useJoinOrganization()
  //const { data: profile } = useProfile({ userId })

  const handleLogout = async () => {
    window.location.replace(`${portalUrl}/logout`)
  }

  const locales = [
    { value: 'en', title: 'English (en)' },
    { value: 'sr', title: 'Srpski (sr)' },
  ]

  const handleChangeLng = (lng: string) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('lng', lng)
  }
  useEffect(() => {
    console.log('TopNavigation rendered')
  }, [])

  // if (hideNavigation) return <></>
  return (
    <>
      <Transition
        show={!hideTopNavigation}
        as='div'
        enter='transition-all duration-80 ease-in-out'
        enterFrom='mt-[-4.185rem]'
        enterTo='mt-0'
        leave='transition-all duration-80 ease-in-out'
        leaveFrom='mt-0'
        leaveTo='mt-[-4.185rem]'
      >
        <NavigationBar className={'h-[4.185rem] border-b border-gray-300 flex items-center justify-between !z-20'}>
          <div className='flex items-center gap-x-3'>
            {!isMobile && (
              <button
                className='hidden md:inline-block rounded-full p-2 hover:bg-sky-50 active:bg-sky-100'
                onClick={() => setSideOpen((old) => !old)}
                data-testid='side-menu-toggle'
              >
                <Bars3Icon className='w-6 h-6' />
              </button>
            )}
            <AppLogo />
          </div>
          <div className='flex items-center gap-x-3 md:gap-x-6'>
            {/* <NotificationsMenuItem
          unreadNotifications={3}
          labels={{
            notifications: t('TopNav.notifications'),
            show_unread: t('TopNav.showUnread'),
            mark_all_as_read: t('TopNav.markAllAsRead'),
          }}
        /> */}
            {isMobile ? (
              <MobileModalWithButton
                buttonContent={<GlobeAltIcon className='h-6 w-6' />}
                className='inline-flex w-full justify-center items-center gap-x-2 px-1 py-2 rounded-md'
              >
                {({ closeModal }) => (
                  <>
                    {locales.map((locale) => (
                      <div key={`language-${locale.value}`} data-testid='nav-language'>
                        <div
                          onClick={() => {
                            handleChangeLng(locale.value)
                            closeModal()
                          }}
                          className='cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2'
                          data-testid='nav-language'
                        >
                          <span className='mr-5'>
                            <span className='absolute'>
                              {i18n.language === locale.value && <CheckIcon className='h-5 w-5' aria-hidden='true' />}
                            </span>
                          </span>
                          {locale.title}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </MobileModalWithButton>
            ) : (
              <Menu as='div' className='relative'>
                <Menu.Button
                  name='nav-language'
                  className='inline-flex w-full justify-center items-center gap-x-2 px-1 py-2 rounded-md hover:bg-sky-50 active:bg-sky-100'
                >
                  <GlobeAltIcon className='h-6 w-6' />
                  <div data-testid='current-language'>{i18n.resolvedLanguage}</div>
                </Menu.Button>
                <Menu.Items className='absolute right-0 z-10 mt-2 py-4 px-4 gap-y-3 w-56 origin-top-right flex flex-col bg-white drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
                  {locales.map((locale) => (
                    <Menu.Item key={`language-${locale.value}`} data-testid='nav-language'>
                      {() => (
                        <div
                          onClick={() => handleChangeLng(locale.value)}
                          className=' cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                          data-testid='nav-language'
                        >
                          {locale.title}
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Menu>
            )}

            {!isMobile && (
              <Menu as='div' className='relative'>
                <Menu.Button
                  className='inline-flex w-full justify-center items-center gap-x-2 p-2 rounded-full hover:bg-sky-50 active:bg-sky-100'
                  name='apps-btn'
                >
                  <AppsIcon />
                </Menu.Button>
                <Menu.Items className='absolute right-0 z-10 mt-2 py-4 px-4 gap-y-3 w-56 origin-top-right flex flex-col bg-white drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
                  {applications
                    ?.filter((app) => app.code !== 's-process')
                    .map((app, index) => (
                      <Menu.Item key={`application-${index}`}>
                        {() => (
                          <div
                            onClick={() =>
                              org ? window.location.replace(`${app.url}/${org}`) : window.location.replace(app.url)
                            }
                            className='cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                            data-testid='nav-app'
                          >
                            <AppIcon code={app.code} />
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  <Menu.Item key='portal'>
                    <div
                      onClick={() =>
                        window.location.replace(
                          `https://portal${
                            process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                          }.sistemiv.com`,
                        )
                      }
                      className=' cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                      data-testid='nav-app'
                    >
                      <div className='font-sans text-indigo-600 text-sm font-semibold flex'>
                        <span>App Portal</span>
                        <ArrowRightIcon className='w-5 h-5 ml-2' />
                      </div>
                    </div>
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            )}

            {isMobile ? (
              <MobileModalWithButton
                buttonContent={
                  <ImgWrapper
                    className={`w-8 h-8 !min-w-8 !min-h-8 rounded-full flex-none flex items-center justify-center border border-sky-500`}
                    src={`${imageBase}/${org}/76`}
                    onErrorComponent={
                      <p
                        className={`bg-[#7b87ae] w-8 h-8 rounded-full text-white flex items-center justify-center border border-sky-500`}
                      >
                        {organizations?.organizations
                          .find((o) => o.organizationCode === org)
                          ?.name?.charAt(0)
                          ?.toUpperCase()}
                      </p>
                    }
                  />
                }
                className='flex items-center justify-center'
              >
                {({ closeModal }) => (
                  <>
                    {organizations?.organizations?.map((organization, index) => (
                      <div
                        key={`organization-${index}`}
                        className='flex items-center gap-x-3 cursor-pointer hover:bg-sky-50 py-1'
                        onClick={() => {
                          if (!org) return
                          navigate(`/${organization.organizationCode}`, { replace: true })
                          closeModal({ clearHistroy: false })
                        }}
                      >
                        <span className='relative flex items-center mr-4'>
                          <span className='absolute'>
                            {organization.organizationCode === org && (
                              <CheckIcon className='h-5 w-5' aria-hidden='true' />
                            )}
                          </span>
                        </span>
                        <div className='flex items-center gap-x-3 py-2.5'>
                          <ImgWrapper
                            className={`w-8 h-8 rounded-full flex-none flex items-center justify-center border ${
                              organization.organizationCode === org ? 'border-sky-500' : 'border-gray-300'
                            }`}
                            src={`${imageBase}/${organization.organizationCode}/76`}
                            onErrorComponent={
                              <p
                                className={`bg-[#7b87ae] w-8 h-8 rounded-full text-white flex items-center justify-center border ${
                                  organization.organizationCode === org ? 'border-sky-500' : 'border-gray-300'
                                }`}
                              >
                                {organization.name?.charAt(0)?.toUpperCase()}
                              </p>
                            }
                          />
                          {organization.name}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </MobileModalWithButton>
            ) : (
              <Menu as='div' className='relative'>
                <Menu.Button className='flex items-center justify-center'>
                  <ImgWrapper
                    className={`w-8 h-8 rounded-full flex-none flex items-center justify-center border border-sky-500`}
                    src={`${imageBase}/${org}/76`}
                    onErrorComponent={
                      <p
                        className={`bg-[#7b87ae] w-8 h-8 rounded-full text-white flex items-center justify-center border border-sky-500`}
                      >
                        {organizations?.organizations
                          .find((o) => o.organizationCode === org)
                          ?.name?.charAt(0)
                          ?.toUpperCase()}
                      </p>
                    }
                  />
                </Menu.Button>
                <Menu.Items className='absolute right-0 z-10 mt-2 py-4 px-4 w-56 origin-top-right max-h-[250px] overflow-auto flex flex-col bg-white drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
                  <div>
                    {organizations?.organizations?.map((organization, index) => (
                      <Menu.Item
                        key={`organization-${index}`}
                        as='div'
                        className='flex items-center gap-x-3 cursor-pointer hover:bg-sky-50'
                        onClick={() => {
                          if (!org) return
                          navigate(`/${organization.organizationCode}`)
                        }}
                      >
                        <div className='flex items-center gap-x-3 py-2.5'>
                          <ImgWrapper
                            className={`w-8 h-8 rounded-full flex-none flex items-center justify-center border ${
                              organization.organizationCode === org ? 'border-sky-500' : 'border-gray-300'
                            }`}
                            src={`${imageBase}/${organization.organizationCode}/76`}
                            onErrorComponent={
                              <p
                                className={`bg-[#7b87ae] w-8 h-8 rounded-full text-white flex items-center justify-center border ${
                                  organization.organizationCode === org ? 'border-sky-500' : 'border-gray-300'
                                }`}
                              >
                                {organization.name?.charAt(0)?.toUpperCase()}
                              </p>
                            }
                          />
                          {organization.name}
                        </div>
                      </Menu.Item>
                    ))}
                  </div>
                  {/* <div>
                <Menu.Item>
                  {() => (
                    <div
                      className='pb-2 cursor-pointer hover:bg-sky-50 inline-flex text-sm text-blue-400'
                      onClick={() => setJoinOrganizationOpen(true)}
                    >
                      {t('TopNav.joinExistingOrg')}
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {() => (
                    <div
                      className='cursor-pointer hover:bg-sky-50 inline-flex text-sm text-blue-400'
                      onClick={() => setCreateOrganizationOpen(true)}
                    >
                      {t('TopNav.createNewOrg')}
                    </div>
                  )}
                </Menu.Item>
              </div> */}
                </Menu.Items>
              </Menu>
            )}

            {isMobile ? (
              <MobileModalWithButton
                buttonContent={
                  <ImgWrapper
                    className='w-8 h-8 !min-w-8 !min-h-8 rounded-full border-sky-500 border'
                    // src={`${imageBase}/${profile?.data?.id}/76`}
                    src={`${imageBase}/${instance.getActiveAccount()?.localAccountId}/76`}
                    onErrorComponent={
                      <p className='bg-[#7b87ae] w-8 h-8 rounded-full text-white flex items-center justify-center border-sky-500 border'>
                        {/* {profile?.data?.firstName?.charAt(0)?.toUpperCase() ?? ''} */}
                        {instance.getActiveAccount()
                          ? (instance.getActiveAccount()?.idTokenClaims?.given_name as string)?.[0]
                          : 'N/A'}
                      </p>
                    }
                  />
                }
                className='inline-flex min-w-fit justify-center items-center px-1 py-2 gap-x-2 rounded-md'
              >
                {() => (
                  <>
                    <div key='portal'>
                      <div
                        onClick={() => {
                          window.location.replace(
                            `https://portal${
                              process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                            }.sistemiv.com`,
                          )
                        }}
                        className=' cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2'
                        data-testid='nav-app'
                      >
                        <div className='font-sans text-indigo-600 text-sm font-semibold flex'>
                          <span>App Portal</span>
                          <ArrowRightIcon className='w-5 h-5 ml-2' />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        onClick={() =>
                          window.location.replace(
                            `https://identity${
                              process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                            }.sistemiv.com`,
                          )
                        }
                        className='pb-2 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0'
                        data-testid='nav-profile-option'
                      >
                        {t('TopNav.profile')}
                      </div>
                    </div>

                    <div className='absolute md:hidden left-0 right-0 w-full h-px bg-gray-300' />
                    <div>
                      <div
                        onClick={handleLogout}
                        className='cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0'
                        data-testid='nav-profile-option'
                      >
                        {t('TopNav.logout')}
                      </div>
                    </div>
                  </>
                )}
              </MobileModalWithButton>
            ) : (
              <Menu as='div' className='relative'>
                <Menu.Button className='flex items-center justify-center'>
                  <ImgWrapper
                    className='w-8 h-8 rounded-full border-sky-500 border'
                    // src={`${imageBase}/${profile?.data?.id}/76`}
                    src={`${imageBase}/${instance.getActiveAccount()?.localAccountId}/76`}
                    onErrorComponent={
                      <p className='bg-[#7b87ae] w-8 h-8 rounded-full text-white flex items-center justify-center border-sky-500 border'>
                        {/* {profile?.data?.firstName?.charAt(0)?.toUpperCase() ?? ''} */}
                        {instance.getActiveAccount()
                          ? (instance.getActiveAccount()?.idTokenClaims?.given_name as string)?.[0]
                          : 'N/A'}
                      </p>
                    }
                  />
                </Menu.Button>
                <Menu.Items className='absolute right-0 z-10 mt-2 py-4 px-4 w-56 origin-top-right flex flex-col bg-white drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
                  <Menu.Item key='portal'>
                    <div
                      onClick={() =>
                        window.location.replace(
                          `https://portal${
                            process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                          }.sistemiv.com`,
                        )
                      }
                      className=' cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2'
                      data-testid='nav-app'
                    >
                      <div className='font-sans text-indigo-600 text-sm font-semibold flex'>
                        <span>App Portal</span>
                        <ArrowRightIcon className='w-5 h-5 ml-2' />
                      </div>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    {() => (
                      <div
                        onClick={() =>
                          window.location.replace(
                            `https://identity${
                              process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                            }.sistemiv.com`,
                          )
                        }
                        className='pb-2 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                        data-testid='nav-profile-option'
                      >
                        {t('TopNav.profile')}
                      </div>
                    )}
                  </Menu.Item>
                  <div className='relative -mx-4 my-1.5'>
                    <div className='w-full h-px bg-gray-300' />
                  </div>
                  <Menu.Item>
                    {() => (
                      <div
                        onClick={handleLogout}
                        className=' cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                        data-testid='nav-profile-option'
                      >
                        {t('TopNav.logout')}
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            )}
          </div>
        </NavigationBar>
      </Transition>

      {/* {joinOrganizationOpen && (
        <JoinOrganizationModal
          open={joinOrganizationOpen}
          setOpen={setJoinOrganizationOpen}
          onJoin={(organization) => {
            joinOrganization(
              { code: organization.code },
              {
                onSuccess: () => {
                  setJoinOrganizationOpen(false)
                  setNotificationMessage(t('TopNav.joinOrgMsg') as string)
                  setNotificationModalOpen(true)
                },
              },
            )
          }}
          isJoining={isJoining}
          fetchNext={() => fetchNextOrgs()}
          setSearch={(value: string) => setSearch(value)}
          options={
            orgPages?.pages
              .flat(Infinity)
              .map((r) => r.organizations)
              .flat()
              ?.map((o) => ({
                id: o.organizationCode,
                code: o.organizationCode,
                image: `${imageBase}/${o.organizationCode}/76`,
                name: o.name,
              })) ?? []
          }
        />
      )}
      {createOrganizationOpen && (
        <CreateOrganizationModal
          isCreating={isCreating}
          open={createOrganizationOpen}
          setOpen={setCreateOrganizationOpen}
          onContinue={(name: string, subdomain: string) => {
            createOrganization(
              { name, code: subdomain },
              {
                onSuccess: () => {
                  setCreateOrganizationOpen(false)
                  setNotificationMessage(t('TopNav.createOrgMsg', { name: name }) as string)
                  setNotificationModalOpen(true)
                },
              },
            )
          }}
        />
      )} */}
      {/* {notificationModalOpen && (
        <SuccessNotification
          open={notificationModalOpen}
          setOpen={setNotificationModalOpen}
          message={notificationMessage}
        />
      )} */}
    </>
  )
}

export default TopNav
