import { Transition } from '@headlessui/react'
import { ChevronDownIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button, Input, MobileModalWithButton } from '@sistemiv/s-components'
import React, { FC, useEffect, useMemo, useState } from 'react'
import CreateProcessInstanceModal from '../create-process-instance/CreateProcessInstanceModal'
import { useParams } from 'react-router-dom'
import { useCurrentProcessDefinition } from '../../../repositories/process-instances/process-definition-selected.repository'
import { useTranslation } from 'react-i18next'
import { useBulkActions } from '../../../repositories/process-instances/process-instances-actions.repository'
import { ProcessTableRow } from '../table/TableProcess'
import classNames from '../../../classNames'
import { useNavigationContext } from '../../../context/ResponsiveContextProvider'

type ActionScope = 'User task' | 'Service task' | 'Process instance'
export interface ProcesInstanceActions {
  id: string
  name: string
  scope: ActionScope
}

type ProcessInstanceHeaderMobileProps = {
  selectedRows: ProcessTableRow[]
  setSelectedRows: React.Dispatch<React.SetStateAction<ProcessTableRow[]>>
  onCreate?: (data: any) => Promise<any>
  onBulkActionClicked?: (action: string) => void
  onCompleteTasksClicked?: () => void
  selectAllMobile: (value: boolean) => void
}
const ProcessInstanceHeaderMobile: FC<ProcessInstanceHeaderMobileProps> = ({
  selectedRows,
  setSelectedRows,
  onBulkActionClicked,
  onCompleteTasksClicked,
  selectAllMobile,
}) => {
  const [selectOpen, setSelectOpen] = useState(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const { setNavigationLock, hideNavigations, hideBottomNavigation } = useNavigationContext()

  const { org, type } = useParams()
  const [isCreateOpen, setCreateOpen] = useState(false)
  const { t } = useTranslation()
  const { data: currentProcess } = useCurrentProcessDefinition({ org, processDefinitionKey: type, versions: true })
  const { data: updateOptions } = useBulkActions(selectedRows?.map((row) => row['id'].value))
  const canCompleteTasks = !!updateOptions?.find((option) => option.id.includes('complete'))
  const processInstanceUpdateOptions =
    updateOptions && updateOptions.filter((action) => action.scope === 'Process instance')
  const userTaskUpdateOptions =
    updateOptions && updateOptions.filter((action) => action.scope === 'User task' && !action.id.includes('complete'))
  const serviceTaskUpdateOptions = updateOptions && updateOptions.filter((action) => action.scope === 'Service task')

  const notStartable = useMemo(() => currentProcess?.versions?.every((el) => el.startable === false), [currentProcess])

  useEffect(() => {
    if (selectedRows.length > 0) {
      setSelectOpen(true)
    }
  }, [selectedRows])

  useEffect(() => {
    if (selectOpen) {
      hideNavigations && hideNavigations(true)
      setNavigationLock && setNavigationLock(true)
    } else {
      setNavigationLock && setNavigationLock(false)
      hideNavigations && hideNavigations(false, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOpen])
  return (
    <div className='flex items-center justify-between w-full' data-testid='process-instance-header'>
      {!selectOpen ? (
        // <div className='flex items-center justify-between w-full'>
        //   <div className='flex items-center gap-x-4'>
        //     <p className='whitespace-nowrap overflow-hidden text-ellipsis max-w-xs'>{currentProcess?.name}</p>
        //   </div>
        //   <MagnifyingGlassIcon className='w-5 h-5 text-gray-500' strokeWidth={2} onClick={() => setSearchOpen(true)} />
        // </div>
        <></>
      ) : (
        <div className='w-full flex flex-col gap-y-3 p-3'>
          <div className='flex flex-row items-center w-full'>
            <div className='flex-1 flex flex-row items-center gap-x-4'>
              <div
                className='pr-2 py-2'
                onClick={() => {
                  setSelectOpen(false)
                  setSelectedRows([])
                }}
              >
                <XMarkIcon className='w-6 h-6 text-gray-500' strokeWidth={2} />
              </div>

              <span>{selectedRows.length} Selected</span>
            </div>
            <div className='flex-1 flex flex-row items-center gap-x-2'>
              {selectedRows.length > 0 && (
                <>
                  <MobileModalWithButton
                    buttonContent={
                      <>
                        <span>{t('Common.update')}</span>
                        <ChevronDownIcon className='w-4 h-4' strokeWidth={2} />
                      </>
                    }
                    className={classNames(
                      'flex-1 text-sm w-full bg-sky-500 text-white text-center rounded-full py-2 flex items-center justify-center space-x-1',
                      selectedRows?.find((row) => row['Resolution'].value !== '') ? '!bg-slate-400' : '',
                    )}
                    disabled={!!selectedRows?.find((row) => row['Resolution'].value !== '')}
                    leftLabel={t('Common.update') as string}
                  >
                    {({ closeModal }) => (
                      <>
                        {processInstanceUpdateOptions && processInstanceUpdateOptions?.length > 0 && (
                          <div>
                            <p className='px-4 py-1.5 text-xs text-gray-400'>{t('ProcessInstance.processInstance')}</p>
                            {processInstanceUpdateOptions?.map((option, index) => (
                              <div key={index}>
                                <div
                                  onClick={() => {
                                    onBulkActionClicked && onBulkActionClicked(option.id)
                                    closeModal({ clearHistroy: false })
                                  }}
                                  className='text-xs px-4 py-3 bg-white cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                                >
                                  {option.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {userTaskUpdateOptions && userTaskUpdateOptions?.length > 0 && (
                          <div className='border-t border-gray-300'>
                            <p className='px-4 py-1.5 text-gray-400 text-xs'>{t('ProcessInstance.userTask')}</p>
                            {userTaskUpdateOptions?.map((option, index) => (
                              <div key={`UToption-${index}`}>
                                <div
                                  onClick={() => {
                                    onBulkActionClicked && onBulkActionClicked(option.id)
                                    closeModal()
                                  }}
                                  className='text-xs px-4 py-3 bg-white cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                                >
                                  {option.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {serviceTaskUpdateOptions && serviceTaskUpdateOptions?.length > 0 && (
                          <div className='border-t border-gray-300'>
                            <p className='px-4 py-1.5 text-gray-400 text-xs'>{t('ProcessInstance.serviceTask')}</p>
                            {serviceTaskUpdateOptions?.map((option, index) => (
                              <div key={index}>
                                <div
                                  onClick={() => {
                                    onBulkActionClicked && onBulkActionClicked(option.id)
                                    closeModal()
                                  }}
                                  className='text-xs px-4 py-3 bg-white cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                                >
                                  {option.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </MobileModalWithButton>

                  {canCompleteTasks ? (
                    <button
                      className='flex-1 bg-sky-500 text-white text-center rounded-full py-2 text-sm'
                      onClick={() => onCompleteTasksClicked && onCompleteTasksClicked()}
                    >
                      {t('Common.complete')}
                    </button>
                  ) : (
                    <div className='flex-1' />
                  )}
                </>
              )}
            </div>
          </div>
          <div className='flex flex-row w-full'>
            <Input
              type='checkbox'
              id='bulkSelect'
              value={selectAllChecked}
              checked={selectAllChecked}
              onChange={(e) => {
                selectAllMobile(e.target.checked)
                setSelectAllChecked(e.target.checked)
              }}
              label={
                (selectAllChecked
                  ? t('ProcessInstance.filters.clearSelection')
                  : t('ProcessInstance.filters.selectAll')) as string
              }
            />
          </div>
        </div>
      )}
      <Transition
        show={!hideBottomNavigation && !notStartable}
        as='div'
        enter='transition-opacity duration-80 ease-in-out'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-80 ease-in-out'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div data-testid='create-button' className='z-20'>
          <Button
            className='fixed mb-16 bottom-4 right-4 bg-blue-500 text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg shadow-blue-500/50'
            onClick={() => setCreateOpen(true)}
          >
            <PlusIcon className='w-10 h-10 scale-150' strokeWidth={2} />
          </Button>
        </div>
      </Transition>

      {/* TODO obirsao sam show notification, pitaj jk sta tu treba */}
      {isCreateOpen && <CreateProcessInstanceModal open={isCreateOpen} setOpen={setCreateOpen} />}
    </div>
  )
}

export default ProcessInstanceHeaderMobile
