import React, { FC, Fragment, useState } from 'react'
import classNames from '../../../classNames'
import { useFilters } from '../../../repositories'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MobileModalWithButton, SideMenuParentArrowIcon } from '@sistemiv/s-components'

const CustomFiltersMobile: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeFilterName, setActiveFilterName] = useState('')
  const { org, type } = useParams()
  const { data: filters } = useFilters({ organization: org, key: type })
  const { t } = useTranslation()

  const handleClickOnFilter = ({ id, name }: { id: string; name: string }) => {
    const activeFilter = searchParams.get('activeFilter')
    if (activeFilter !== id) {
      setSearchParams({ activeFilter: id })
      setActiveFilterName(name)
    } else {
      clearFilters()
    }
  }

  const clearFilters = () => {
    setSearchParams()
    setActiveFilterName('')
  }
  if (filters?.length == 0) return <></>
  return (
    <div className='w-full items-center flex gap-x-3 overflow-x-auto no-scrollbar'>
      <MobileModalWithButton
        className={classNames(
          activeFilterName
            ? 'flex flex-row gap-x-1 items-center text-sm rounded-full border bg-blue-100 !border-blue-300 px-2 py-1'
            : ' text-blue-500',
        )}
        buttonContent={
          activeFilterName ? (
            <>
              <span className='whitespace-nowrap'>{activeFilterName} </span>
              <SideMenuParentArrowIcon className='w-3 h-3 fill-black rotate-90' />
            </>
          ) : (
            <span className='text-sm'>{t('ProcessInstance.filters.applyCustomFilter')}</span>
          )
        }
        leftLabel={t('ProcessInstance.filters.customFilter') as string}
        rightLabelContent={
          <span
            className={classNames(searchParams.has('activeFilter') ? 'text-blue-500' : 'text-gray-400')}
            onClick={clearFilters}
          >
            {t('ProcessInstance.filters.clearFilter')}
          </span>
        }
      >
        {({ closeModal }) => (
          <>
            <div className='border-b border-b-gray-200'></div>
            {filters?.map((filter) => (
              <div key={filter.id}>
                <div
                  onClick={() => {
                    handleClickOnFilter({ id: filter.id, name: filter.name })
                    closeModal()
                  }}
                  className='pl-2'
                  style={{ backgroundColor: filter.color }}
                >
                  <div
                    className={classNames(
                      'flex flex-row items-center justify-between p-1.5 cursor-pointer border-b border-b-gray-200 hover:bg-sky-50',
                      searchParams.get('activeFilter') === filter.id ? 'bg-gray-100' : 'bg-white',
                    )}
                  >
                    <span
                      data-testid='custom-filter-name'
                      className='whitespace-nowrap overflow-hidden text-ellipsis'
                      style={{ color: filter.color }}
                    >
                      {filter.name}
                    </span>
                    <span></span>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </MobileModalWithButton>
      {searchParams.has('activeFilter') && (
        <span className='text-sm cursor-pointer text-blue-500 whitespace-nowrap' onClick={clearFilters}>
          {t('ProcessInstance.filters.clearCustomFilter')}
        </span>
      )}
    </div>
  )
}

export default CustomFiltersMobile
