import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../../services/ProcessInstances.service'

type ProcessInstanceResumeOptions = {
  id: string
}

export const useProcessInstanceResume = () => {
  const queryClient = useQueryClient()
  const { org, id } = useParams()
  return useMutation<any, Error, ProcessInstanceResumeOptions>({
    mutationKey: ['process-instance-resume'],
    mutationFn: (payload) => ProcessInstancesService.resumeProcessInstance(payload.id, org!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['process-instances', org, id] })
    },
  })
}
