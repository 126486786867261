import Table from '@sistemiv/s-components/dist/esm/components/table/Table'
import React, { FC, useEffect, useState } from 'react'
import { useIsMobile } from '../../../context/ResponsiveContextProvider'

export type TabField = {
  key: string
  label: string
}
export type DataField = {
  label: string
  key: string
  type: string
  value: any
  image?: string
}
export type DataEntry = {
  fields: DataField[]
}
type TabData = {
  tabKey: string
  data: DataEntry[]
}
type VariablesTabProps = {
  variableTabs: TabField[]
  variableData: TabData[]
}

function camelCaseToWords(camelCaseString) {
  const stringWithSpaces = camelCaseString.replace(/([A-Z])/g, ' $1')
  const stringWithCapitals = stringWithSpaces.trim().replace(/\b\w/g, (firstChar) => firstChar.toUpperCase())
  return stringWithCapitals
}

const VariablesTab: FC<VariablesTabProps> = ({ variableTabs, variableData }) => {
  const [activeTab, setActiveTab] = useState(variableTabs[0].key)
  const [tabData, setActiveTabData] = useState<TabData | null>(null)
  const isMobile = useIsMobile()

  const getTableData = (value: any[]) => {
    return !value || value.length === 0
      ? []
      : value?.map((obj) => {
          return Object.keys(obj).reduce((acc, key) => {
            acc[key] = { type: 'value', value: obj[key] }
            return acc
          }, {})
        })
  }

  const getTableHeaders = (value: any[]) =>
    Object.keys(value[0]).map((el) => {
      return { accessor: el, label: camelCaseToWords(el) }
    })

  useEffect(() => {
    const newData = variableData.find((d) => d.tabKey === activeTab)
    setActiveTabData(newData ? newData : null)
  }, [activeTab, variableData])

  return (
    <div className='overflow-auto'>
      {(variableTabs.length > 1 || variableTabs[0].key !== 'other') && (
        <div className='flex items center gap-x-8'>
          {variableTabs.map((tab) => (
            <button
              key={`tab-${tab.key}`}
              onClick={() => setActiveTab(tab.key)}
              className={`py-3 border-b-2 border-gray-300 text-gray-800 ${
                activeTab === tab.key ? '!text-blue-500 !border-blue-500' : ''
              }
                `}
            >
              {tab.label}
            </button>
          ))}
        </div>
      )}

      {isMobile ? (
        <div className='mt-4 text-sm'>
          {tabData?.data.map((variable) =>
            variable?.fields.map((f, i) => (
              <div key={i} className='mb-4'>
                <p className='text-[#8087A0]'>{camelCaseToWords(f.label)}</p>
                <div>
                  {f.type === 'Table' && f.value !== 'N/A' && f.value.length > 0 ? (
                    <Table
                      key={i}
                      data={getTableData(f?.value)}
                      headers={getTableHeaders(f.value)}
                      clickable={false}
                      fieldClassName='w-fit'
                    />
                  ) : f.type === 'Json' && f.value !== 'N/A' ? (
                    <pre
                      key={`${f.label}${i}`}
                      className='break-normal max-h-[350px] overflow-auto text-wrap border border-solid border-slate-500 p-1'
                    >
                      {f.value}
                    </pre>
                  ) : (
                    <p key={`${f.label}${i}`} className='gap-2.5 px-0 py-1'>
                      {f.value.toString() || 'N/A'}
                    </p>
                  )}
                </div>
              </div>
            )),
          )}
        </div>
      ) : (
        <table className='min-w-20'>
          {tabData?.data.map((variable) =>
            variable?.fields.map((f, i) => (
              <tr key={i}>
                <th
                  scope='row'
                  className='text-left mr-20 text-[#8087A0] gap-2.5 px-0 py-2.5 max-w-full overflow-hidden text-ellipsis whitespace-nowrap'
                >
                  {camelCaseToWords(f.label)}
                </th>
                <td className='w-20'></td>
                <td className='pt-3 pb-3'>
                  {f.type === 'Table' && f.value !== 'N/A' && f.value.length > 0 ? (
                    <Table
                      key={i}
                      data={getTableData(f?.value)}
                      headers={getTableHeaders(f.value)}
                      clickable={false}
                      fieldClassName='w-fit'
                    />
                  ) : f.type === 'Json' && f.value !== 'N/A' ? (
                    <pre
                      key={`${f.label}${i}`}
                      className='break-normal max-h-[350px] overflow-auto text-wrap border border-solid border-slate-500 p-1'
                    >
                      {f.value}
                    </pre>
                  ) : (
                    // <div
                    //   key={`${f.label}${i}`}
                    //   className='flex items-start cursor-auto overflow-auto  h-[200px] text-wrap mt-1.5 rounded border border-solid border-slate-500  bg-white p-2  text-slate-500'
                    // >
                    //   <pre className='text-wrap'> {f.value.toString()}</pre>
                    // </div>
                    <p key={`${f.label}${i}`} className='gap-2.5 px-0 py-2.5'>
                      {f.value.toString() || 'N/A'}
                    </p>
                  )}
                </td>
              </tr>
            )),
          )}
        </table>
      )}

      {/* <div className='my-8 w-full py-1 grid grid-flow-col auto-cols-max gap-x-16'>
        <div className='max-w-fit mr-8'>
          {tabData?.data.map((variable) =>
            variable.fields.map((f, i) => (
              <p
                key={`${f.label}${i}`}
                className=' text-[#8087A0] gap-2.5 px-0 py-2.5 max-w-full overflow-hidden text-ellipsis whitespace-nowrap'
              >
                {camelCaseToWords(f.label)}
              </p>
            )),
          )}
        </div>
        <div className='min-w-20 max-w-fit'>
          {tabData?.data.map((variable) => {
            return variable.fields.map((f, i) => {
              if (f.type === 'Table') {
                return (
                  <Table
                    key={i}
                    data={getTableData(f.value)}
                    headers={getTableHeaders(f.value)}
                    clickable={false}
                    fieldClassName='w-fit'
                  />
                )
              } else if (f.type === 'Json') {
                return (
                  // <pre key={`${f.label}${i}`} className='break-normal w-[800px] text-wrap'>
                  //   {f.value.toString()}
                  // </pre>
                  <div
                    key={`${f.label}${i}`}
                    className='flex items-start cursor-auto overflow-auto max-w-[800px] h-[200px] text-wrap mt-1.5 rounded border border-solid border-slate-500  bg-white p-2  text-slate-500'
                  >
                    <pre className='text-wrap'> {f.value.toString()}</pre>
                  </div>
                )
              } else
                return (
                  <p key={`${f.label}${i}`} className='gap-2.5 px-0 py-2.5'>
                    {f.value.toString()}
                  </p>
                )
            })
          })}
        </div>
      </div> */}
    </div>
  )
}

export default VariablesTab
