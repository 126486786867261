import { PlusIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/24/solid'
import { ColorSelect, IconPicker, Base64SVG, Button, SideMenuParentArrowIcon } from '@sistemiv/s-components'
import React, { FC, useState } from 'react'
import { ProcessDefinition } from '../../../../models/ProcessDefinitionSettings'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { IconOption } from '@sistemiv/s-components/dist/esm/components/icon-picker/IconPicker'
import { useNavigate, useParams } from 'react-router-dom'
import ProcessDefinitionsSettings from '../../../../services/ProcessDefinitionsSettings'
import { imageBase } from '../../../../services/http-common'
import AddProcessVersionModal from '../add-process-modal/AddProcessVersionModal'
import EditProcessModal from '../add-process-modal/EditProcessModal'
import AddProcessModal from '../add-process-modal/AddProcessModal'
import { useTranslation } from 'react-i18next'

const ProcessItem: FC<{
  item: ProcessDefinition
  depth: number
  icons: IconOption[]
  colors: string[]
  users: any[]
  collapsed?: boolean
  handleCollapse?(id: string): void
  setColors?(value: string[]): void
  setItem: DispatchSetAction<any>
  // setAccessModalOpen: DispatchSetAction<boolean>
  refreshData?(): void
}> = ({
  item,
  depth,
  icons,
  colors,
  users,
  setColors,
  setItem,
  //setAccessModalOpen,
  collapsed,
  handleCollapse,
  refreshData,
}) => {
  const { org } = useParams()
  const [addProcessVersionModalOpen, setAddProcessVersionModalOpen] = useState(false)
  const [editProcessOpen, setEditProcessOpen] = useState(false)
  const [addProcessOpen, setAddProcessOpen] = useState(false)
  const [base64file, setBase64file] = useState('')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleCheckFile = async (base64: string) => {
    if (!org) return
    const response = await ProcessDefinitionsSettings.exists(org, { deploymentPackage: base64 })
    if (!response) {
      setAddProcessVersionModalOpen(false)
      setAddProcessOpen(true)
      setBase64file(base64)
    }
    if (item.id !== response.id) {
      return {
        ...response,
        msg: t('Settings.msg', { process: response.name }),
        //`This new process version will be added to the "${response.name}" process.`,
      }
    }

    return response
  }

  const handleAddNewVersion = (body: any) => {
    if (!org) return
    ProcessDefinitionsSettings.addProcessVersion(org, body.id, {
      versionTag: body.versionName,
      deploymentPackage: body.base64File,
      makeCurrent: body.currentVersionSelected,
    }).then(() => {
      setAddProcessVersionModalOpen(false)
      refreshData?.()
    })
  }

  // TODO: check
  const handleAddProcess = (body: any) => {
    if (!org) return
    setIsLoading(true)
    if (body.addingVersion && body.id !== '') {
      ProcessDefinitionsSettings.addProcessVersion(org, body.id, {
        versionTag: body.versionName,
        deploymentPackage: body.base64File,
        makeCurrent: body.currentVersionSelected,
      })
        .then(() => {
          setAddProcessOpen(false)
          ProcessDefinitionsSettings.list(org, { versions: true }).then(() => refreshData?.())
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      ProcessDefinitionsSettings.create(org, body)
        .then(() => {
          setAddProcessOpen(false)
          ProcessDefinitionsSettings.list(org, { versions: true }).then(() => refreshData?.())
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const onUpdateProcess = (field: string, value: any, modal?: boolean) => {
    if (!org) return
    const body = {}
    let apiField: string | null = null
    switch (field) {
      case 'color':
        apiField = 'color'
        body['color'] = value
        break
      case 'icon':
        apiField = 'icon'
        body['icon'] = value.base64string
        break
      case 'code':
        apiField = 'code'
        body['code'] = value
        break
      case 'name':
        apiField = 'name'
        body['name'] = value
        break
      case 'assignee':
        apiField = 'escalation-assignee'
        body['escalationAssigneeId'] = value.objectId
        break
      default:
        return
    }

    if (apiField) {
      ProcessDefinitionsSettings.update(org, item.id, apiField, body).then(() => {
        !modal && refreshData?.()
      })
    }
  }

  return (
    <div className='grid grid-cols-2 gap-x-20'>
      <div className={`flex items-center gap-x-3 ${depth > 0 ? 'pl-8' : ''}`}>
        {item.versions && (
          <button onClick={() => handleCollapse?.(item.id)}>
            <SideMenuParentArrowIcon className={`w-3 h-3 fill-black ${collapsed ? 'rotate-0' : 'rotate-90'}`} />
          </button>
        )}
        <ColorSelect
          value={(item as ProcessDefinition).color}
          onChange={(value) => onUpdateProcess?.('color', value)}
          options={colors}
          onNewColorAdd={(color) => setColors && setColors([...colors, color])}
        />
        <div className='relative'>
          <IconPicker setSelected={(icon: any) => onUpdateProcess?.('icon', icon)} options={icons}>
            <Base64SVG
              base64image={item.icon ?? ''}
              className='[&>svg>*]:fill-slate-500 [&>svg>*]:hover:fill-gray-800 [&>svg]:w-7 [&>svg]:h-7'
            />
          </IconPicker>
        </div>

        <span className='whitespace-nowrap text-ellipsis overflow-hidden'>
          {item.code} {item.name}
        </span>
      </div>
      <div className='flex items-center justify-end align-end gap-x-5'>
        <div className='flex items-center'>
          <Button
            dataTestId={`process-${item.code}-add-version`}
            onClick={() => setAddProcessVersionModalOpen(true)}
            className='!py-1 px-2.5 rounded-md flex items-center gap-x-2 border border-solid border-blue-500 text-blue-500 bg-transparent hover:bg-white'
          >
            <PlusIcon className='w-4 h-4' />
            {t('Settings.version')}
          </Button>
        </div>
        <div className='flex items-center justify-end align-end gap-x-5 opacity-0 group-hover:opacity-100'>
          <Button
            className='!p-0'
            tooltip={t('Settings.tooltips.editDefinition') as string}
            dataTestId={`process-${item.code}-edit`}
            onClick={() => {
              setEditProcessOpen(true)
            }}
          >
            <PencilIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
          </Button>
          <Button
            className='!p-0'
            tooltip={t('Settings.tooltips.editAccess') as string}
            dataTestId={`process-${item.code}-edit-access`}
            onClick={() => {
              setItem(item)
              navigate(`/${org}/settings/processes/${item.id}`)
            }}
          >
            <UserCircleIcon className='w-5 h-5 text-slate-500 hover:text-gray-800' />
          </Button>
          {/* <Button className='!p-0 group' disabled={true} tooltip='Export process definition'>
            <ExportProcessIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800 group-disabled:fill-slate-500 group-disabled:opacity-50' />
          </Button> */}
          <Button
            className='!p-0 text-blue-500'
            dataTestId={`process-${item.code}-config`}
            onClick={() =>
              navigate(
                `/${org}/settings/flow?process=${item.id}&key=${item.processDefinitionKey}&version=${
                  item.versions?.find((version) => version.current)?.id
                }&versionId=`,
              )
            }
          >
            {t('Settings.config')}
          </Button>
          {/* <Button
            className='!p-0 text-blue-500'
            dataTestId={`process-${item.code}-config`}
            onClick={() => navigate(`/${org}/settings/processes/${item.id}`)}
          >
            {t('Settings.seeAll')}
          </Button> */}
        </div>
      </div>
      {addProcessVersionModalOpen && (
        <AddProcessVersionModal
          open={addProcessVersionModalOpen}
          // setAsCurrent={item.activationOfPreviousVersionsDisabled}
          setOpen={setAddProcessVersionModalOpen}
          onCheckFile={handleCheckFile}
          onAdd={handleAddNewVersion}
        />
      )}
      {editProcessOpen && (
        <EditProcessModal
          process={item}
          value={users.map((x: any) => ({ ...x, value: x.name, icon: `${imageBase}/${x.objectId}/76` }))}
          open={editProcessOpen}
          setOpen={setEditProcessOpen}
          onUpdate={onUpdateProcess}
          icons={icons}
          colors={colors}
          setColors={setColors}
          onClose={refreshData}
        />
      )}
      {addProcessOpen && (
        <AddProcessModal
          open={addProcessOpen}
          options={users.map((x: any) => ({ ...x, value: x.name, icon: `${imageBase}/${x.objectId}/76` }))}
          setOpen={setAddProcessOpen}
          hasFileData={true}
          base64File={base64file}
          onAdd={handleAddProcess}
          checkFile={handleCheckFile}
          icons={icons}
          colors={colors}
          isLoading={isLoading}
          setColors={setColors}
        />
      )}
    </div>
  )
}

export default ProcessItem
