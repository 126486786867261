import { XMarkIcon } from '@heroicons/react/24/outline'
import { Input } from '@sistemiv/s-components'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from '../../../classNames'

const HeaderSearchSearchMobile: FC<{
  onSearch?(id: string, option: string, value: any): void
  value: any
}> = ({ onSearch, value }) => {
  const [query, setQuery] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    setQuery(value?.value ?? '')
  }, [value, setQuery])

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      onSearch?.('processInstanceCode|name|description', 'like', query)
    }, 500)

    return () => clearTimeout(delayTyping)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <>
      <Input
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        id='instance-mobile-search'
        type='search'
        placeholder={t('Common.search') as string}
        classes2='!bg-gray-100'
      />
      <XMarkIcon
        className={classNames('w-6 h-6 text-gray-500 absolute right-7', query === '' ? 'hidden' : '')}
        onClick={() => setQuery('')}
      />
    </>
  )
}

export default HeaderSearchSearchMobile
