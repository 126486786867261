import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import Processes from './processes-settings/Processes'
import FiltersPage from './processes-settings/filters/FiltersPage'
import PermissionsPage from './global-settings/permissions/PermissionsPage'
import PrioritiesPage from './global-settings/priorities/PrioritiesPage'
import LinkTypes from './global-settings/link-types/LinkTypes'
import Notifications from './global-settings/notifications/Notifications'
import VariablesTab from './processes-settings/general/VariablesTab'
import WebhooksPage from './processes-settings/webhooks/WebhooksPage'
import Flow from './processes-settings/general/Flow'
import ProcessAccessPage from './processes-settings/process-access/ProcessAccessPage'

const SettingsSwitch: FC = () => {
  const { type, id } = useParams()

  switch (type) {
    case 'processes':
      return id ? <ProcessAccessPage /> : <Processes />
    case 'flow':
      return <Flow />
    case 'variables':
      return <VariablesTab />
    case 'filters':
      return <FiltersPage />
    case 'webhooks':
      return <WebhooksPage />
    case 'permissions':
      return <PermissionsPage />
    case 'priorities':
      return <PrioritiesPage />
    case 'link_types':
      return <LinkTypes />
    case 'notifications':
      return <Notifications />
    default:
      return <Processes />
  }
}

export default SettingsSwitch
