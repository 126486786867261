import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from '../../../classNames'
import { DataField, SpinnerIcon, TabPanel, Tabs } from '@sistemiv/s-components'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Combobox } from '@headlessui/react'

const AssigneeSearchMobile: FC<{
  options: TableField[]
  value: TableField[]
  singleValue?: TableField
  loading?: boolean
  onChange?: (value?: TableField[]) => void
  onChangeSingle?: (value?: any) => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  single?: boolean
}> = ({ options, value, loading, onChange, setIsOpen, single, singleValue, onChangeSingle }) => {
  const { t } = useTranslation()
  const tabs = [t('Users'), t('Groups'), t('Org chart')]
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [query, setQuery] = useState<string>('')
  const filteredData =
    query === ''
      ? options.filter((o) => o.tab === selectedTab)
      : options.filter((option) => {
          return option.value.toLowerCase().includes(query.toLowerCase()) && option.tab === selectedTab
        })

  const filteredOptions =
    query === ''
      ? options
      : options.filter((o) =>
          o.value.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        )
  return (
    <div className='fixed inset-0 z-50 bg-white p-2 pb-20 pt-6 !mt-0'>
      <div className='flex flex-col w-full p-1'>
        {!single ? (
          <Combobox
            value={value.map((o) => o.value)}
            onChange={(value) => onChange && onChange(options.filter((f) => value.includes(f.value)))}
            multiple
          >
            <div className='relative'>
              <div className='relative w-full border-b border-gray-300 cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-0 sm:text-sm'>
                <Combobox.Input
                  className={'w-full border-none py-2 pl-10 pr-3 text-sm leading-5 text-gray-900 focus:ring-0'}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder={t('Common.search') + '...'}
                  name='table-grid-filter-input'
                  autoFocus={true}
                />
                <Combobox.Button
                  onClick={() => setIsOpen(false)}
                  className='absolute inset-y-0 left-0 flex items-center pl-2'
                >
                  <ArrowLeftIcon className='w-5 h-5 text-gray-400' />
                </Combobox.Button>
              </div>
              <Combobox.Options
                className={'relative max-h-60 w-full py-1 text-base focus:outline-none sm:text-sm'}
                static
              >
                <div className='flex items-center justify-start gap-x-2 text-sm'>
                  {tabs.map((tab, index) => (
                    <div
                      className={classNames(
                        'whitespace-nowrap py-3 mx-3 text-gray-600 border-b-2 border-gray-300 cursor-pointer',
                        selectedTab === tab ? '!text-sky-500 !border-sky-500' : '',
                      )}
                      key={`tab-${index}`}
                      onClick={() => setSelectedTab(tab)}
                    >
                      {tab}
                    </div>
                  ))}
                </div>
                {loading ? (
                  <div className='flex items-center justify-center w-full'>
                    <SpinnerIcon className='w-4 h-4 text-sky-500' />
                  </div>
                ) : filteredData.length === 0 && query !== '' ? (
                  <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                    {t('ProcessInstance.filters.nothingFound')}
                  </div>
                ) : (
                  filteredData.map((option, index) => (
                    <Combobox.Option
                      key={`option-${index}`}
                      value={option.value}
                      className='relative cursor-pointer select-none py-2 px-4 text-gray-900'
                    >
                      {({ selected }) => (
                        <div className='flex items-center justify-start gap-x-4'>
                          <input
                            type='checkbox'
                            readOnly
                            checked={selected}
                            className={classNames(
                              'cursor-pointer h-4.5 w-4.5 text-sky-500 rounded-sm disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0',
                            )}
                          />
                          <DataField
                            field={option}
                            className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[15rem] [&>div>svg]:w-5 [&>div>svg]:h-5'
                          />
                        </div>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </div>
          </Combobox>
        ) : (
          <Combobox
            onChange={(value) => {
              if (onChangeSingle) {
                onChangeSingle(value)
              }
              setIsOpen(false)
            }}
          >
            <div className='w-full max-h-full overflow-hidden'>
              <Combobox.Options static={true}>
                <div className='relative w-full border-b border-gray-300 cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-0 sm:text-sm'>
                  <Combobox.Input
                    className={'w-full border-none py-2 pl-10 pr-3 text-sm leading-5 text-gray-900 focus:ring-0'}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={t('Common.search') + '...'}
                    name='table-grid-filter-input'
                    autoFocus={true}
                  />
                  <Combobox.Button
                    onClick={() => setIsOpen(false)}
                    className='absolute inset-y-0 left-0 flex items-center pl-2'
                  >
                    <ArrowLeftIcon className='w-5 h-5 text-gray-400' />
                  </Combobox.Button>
                </div>
                <Tabs active={selectedTab} tabs={tabs} onClick={setSelectedTab} className='px-3 [&+div]:!pt-2'>
                  <TabPanel active={selectedTab} tab='Users'>
                    {filteredOptions
                      .filter((o) => o.tab === 'Users')
                      .map((o) => (
                        <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                          <div
                            className={classNames(
                              'flex items-center gap-x-3 py-2 px-3',
                              singleValue?.id === o.id ? 'opacity-100' : 'opacity-70',
                            )}
                          >
                            <DataField field={o} />
                          </div>
                        </Combobox.Option>
                      ))}
                  </TabPanel>
                  <TabPanel active={selectedTab} tab='Groups'>
                    {filteredOptions
                      .filter((o) => o.tab === 'Groups')
                      .map((o) => (
                        <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                          <div
                            className={classNames(
                              'flex items-center gap-x-3 py-2 px-3',
                              singleValue?.id === o.id ? 'opacity-100' : 'opacity-70',
                            )}
                          >
                            <DataField field={o} className='[&>div>svg]:w-5 [&>div>svg]:h-5' />
                          </div>
                        </Combobox.Option>
                      ))}
                  </TabPanel>
                  <TabPanel active={selectedTab} tab='Org chart'>
                    {filteredOptions
                      .filter((o) => o.tab === 'Org chart')
                      .map((o) => (
                        <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                          <div
                            className={classNames(
                              'flex items-center gap-x-3 py-2 px-3',
                              singleValue?.id === o.id ? 'opacity-100' : 'opacity-70',
                            )}
                          >
                            <DataField field={o} />
                          </div>
                        </Combobox.Option>
                      ))}
                  </TabPanel>
                </Tabs>
              </Combobox.Options>
            </div>
          </Combobox>
        )}
      </div>
    </div>
  )
}

export default AssigneeSearchMobile
