import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { PrinterIcon } from '@heroicons/react/24/solid'
import { ImgWrapper, MobileModalWithButton } from '@sistemiv/s-components'
import { TableRow } from '@sistemiv/s-components/dist/esm/components/table/Table'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import DownloadIcon from '../../../assets/icons/DownloadIcon'
import { useTranslation } from 'react-i18next'
import classNames from '../../../classNames'

type AttachmentItemMobileProps = {
  item: TableRow
  onDownloadClick: ((id: string) => void) | undefined
  onPrintClick: (id: string) => Promise<void>
  setHighlightedRows: React.Dispatch<React.SetStateAction<TableRow[]>>
}
const AttachmentItemMobile: FC<AttachmentItemMobileProps> = ({
  item,
  onDownloadClick,
  onPrintClick,
  setHighlightedRows,
}) => {
  const { t } = useTranslation()
  const [isSelected, setIsSelected] = useState<boolean>(false)
  item['attachment'].tooltip = undefined

  useEffect(() => {
    if (isSelected) {
      setHighlightedRows((prev) => [...prev, item])
    } else {
      setHighlightedRows((prev) => prev.filter((row) => row['id'].value != item['id'].value))
    }
  }, [isSelected, setHighlightedRows, item])

  return (
    <div
      onContextMenu={(e) => e.preventDefault()}
      className={classNames(
        'max-w-screen flex justify-between py-2 select-none items-center',
        isSelected ? 'bg-sky-50' : '',
      )}
    >
      {isSelected ? (
        <input
          type='checkbox'
          checked={isSelected}
          onChange={(e) => setIsSelected(e.target.checked)}
          className={`form-checkbox h-6 w-6 rounded border-none outline-none focus:outline-none focus-visible:outline-none focus:ring-0 bg-[#CAD1F8] mr-4`}
        />
      ) : (
        <div className='mr-4' onClick={() => setIsSelected(true)}>
          <ImgWrapper
            localSrc={item['attachment'].icon}
            className='w-6 h-6'
            onErrorComponent={
              <div className='rounded-full bg-slate-400 text-white flex items-center justify-center aspect-square w-6'>
                <p>{item['attachment'].value?.[0]}</p>
              </div>
            }
          />
        </div>
      )}

      <div
        className='flex-1 flex flex-col overflow-hidden min-w-0'
        onClick={() =>
          item['attachment'].onAttachmentClicked &&
          item['attachment'].onAttachmentClicked(item['attachment'].id!, item['attachment'].value)
        }
      >
        <div className='text-sm font-semibold text-black'>
          <div className='flex items-center gap-x-2'>
            <p className='line-clamp-2'>{item['attachment'].value}</p>
          </div>
        </div>
        <div className='text-sm text-gray-500 truncate'>{item['data'].value + ' • ' + item['date'].value}</div>
      </div>

      <MobileModalWithButton
        className='w-10 h-10 inline-flex items-center justify-center'
        buttonContent={<>{!isSelected && <EllipsisVerticalIcon className='w-6 h-6 text-black fill-black' />}</>}
      >
        {({ closeModal }) => (
          <>
            <div className='w-full flex flex-row py-1.5 items-center'>
              <ImgWrapper
                localSrc={item['attachment'].icon}
                className='w-6 h-6'
                onErrorComponent={
                  <div className='rounded-full bg-slate-400 text-white flex items-center justify-center aspect-square w-6'>
                    <p>{item['attachment'].value?.[0]}</p>
                  </div>
                }
              />
              <span className='pl-2'>{item['attachment'].value}</span>
            </div>
            <div className='absolute left-0 right-0 w-full h-px bg-gray-300' />

            <div
              onClick={() => {
                onDownloadClick && onDownloadClick(item['attachment'].id as string)
                closeModal()
              }}
              className='w-full flex flex-row py-1.5 items-center'
            >
              <DownloadIcon className='w-6 h-6 text-gray-500 fill-gray-500' />
              <span className='text-gray-700 pl-2'>{t('ProcessInstance.attachments.download')}</span>
            </div>
            <div
              onClick={() => {
                onPrintClick && onPrintClick(item['attachment'].id as string)
                closeModal()
              }}
              className='w-full flex flex-row py-1.5 items-center'
            >
              <PrinterIcon className='w-6 h-6 text-gray-500' />
              <span className='text-gray-700 pl-2'>{t('ProcessInstance.attachments.print')}</span>
            </div>
          </>
        )}
      </MobileModalWithButton>
    </div>
  )
}

export default AttachmentItemMobile

type LongPressOptions = {
  onLongPress: () => void
  delay?: number
}

type EventHandlers = {
  onMouseDown: () => void
  onMouseUp: () => void
  onMouseLeave: () => void
  onTouchStart: () => void
  onTouchEnd: () => void
}

export const useLongPress = ({ onLongPress, delay = 500 }: LongPressOptions): EventHandlers => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const start = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      onLongPress()
    }, delay)
  }, [onLongPress, delay])

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  return {
    onMouseDown: start,
    onMouseUp: clear,
    onMouseLeave: clear,
    onTouchStart: start,
    onTouchEnd: clear,
  }
}

// ovo ide u javascript komponente
// const longPressHandlers = useLongPress({
//   onLongPress: () => {
//     setIsSelected((prev) => !prev)
//   },
//   delay: isSelected ? 0 : 600,
// })

// ovo ide kao atribut diva
// {...longPressHandlers}
