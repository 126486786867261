import { ProcessDefinitionResponse, ProcessVersions, UserTasksModel } from '../models/ProcessDefinitionSettings'
import UserService from './UserService'
import axiosInstance, { axiosMgmtInstance } from './http-common'
class ProcessDefinitionsSettings {
  async list(
    organization: string,
    params?: {
      search?: string
      rootOnly?: boolean
      type?: boolean
      versions?: boolean
      hideDeactivatedVersions?: boolean
    },
  ): Promise<ProcessDefinitionResponse[]> {
    return (await axiosMgmtInstance.get(`/${organization}/processes`, { params })).data
  }

  async create(organization: string, body: any) {
    return (await axiosMgmtInstance.post(`/${organization}/processes`, body)).data
  }

  async update(organization: string, id: string, field: string, body: any) {
    return (await axiosMgmtInstance.post(`${organization}/process-items/${id}/${field}`, body)).data
  }

  async createCategory(organization: string, body: any) {
    return (await axiosMgmtInstance.post(`/${organization}/processes/categories`, body)).data
  }

  async listProcessVersions(organization: string, id: string): Promise<ProcessVersions[]> {
    return (await axiosInstance.get(`/${organization}/processes/key/${id}/versions`)).data
  }

  async changeVersionStatus(organization: string, id: string, versionId: string, status: boolean) {
    return (
      await axiosMgmtInstance.post(`/${organization}/process-items/${id}/version/${versionId}/status`, {
        active: status,
      })
    ).data
  }

  async addProcessVersion(organization: string, id: string, body: any) {
    return (await axiosMgmtInstance.post(`/${organization}/process-items/${id}/versions`, body)).data
  }

  async editProcessVersion(organization: string, id: string, versionId: string, body) {
    return (await axiosMgmtInstance.post(`/${organization}/process-items/${id}/tag/${versionId}`, body)).data
  }

  async exists(organization: string, body: any) {
    return (await axiosMgmtInstance.post(`/${organization}/processes/exists`, body)).data
  }

  async listAllUserTasks(organization: string, id: string): Promise<UserTasksModel> {
    return (await axiosMgmtInstance.get(`/${organization}/processes/${id}/user-tasks`)).data
  }

  async listAllServiceTasks(organization: string, id: string): Promise<string[]> {
    return (await axiosMgmtInstance.get(`/${organization}/processes/${id}/service-tasks`)).data
  }

  async listAllResolutions(organization: string, id: string): Promise<string[]> {
    return (await axiosMgmtInstance.get(`/${organization}/processes/${id}/resolutions`)).data
  }

  async getProcessAccess(organization, id) {
    const processes: any = (await axiosMgmtInstance.get(`/${organization}/processes/${id}/access`)).data
    const users: any = await UserService.listAllUsersInOrg(organization)
    const allUsers: any[] = users?.users
    const res = processes?.users?.map((user) => {
      return { ...user, ...allUsers?.find((_user) => _user.objectId === user.id) }
    })
    return res
  }

  // Add process group access
  async addGroupAccess(organization: string, id: string, processAccessId: string, groupId: string) {
    const body = {
      processAccessId,
      groupId,
    }
    return (await axiosMgmtInstance.post(`/${organization}/process-items/${id}/group-access`, body)).data
  }
  // Remove process group access
  async removeGroupAccess(organization: string, id: string, processAccessId: string, groupId: string) {
    const body = {
      processAccessId,
      groupId,
    }
    return (await axiosMgmtInstance.delete(`/${organization}/process-items/${id}/group-access`, { data: body })).data
  }

  // Add process node access
  async addOrgNodeAccess(organization: string, id: string, processAccessId: string, nodeId: string) {
    const body = {
      processAccessId,
      nodeId,
    }
    return (await axiosMgmtInstance.post(`/${organization}/process-items/${id}/node-access`, body)).data
  }
  // Remove process node access
  async removeOrgNodeAccess(organization: string, id: string, processAccessId: string, nodeId: string) {
    const body = {
      processAccessId,
      nodeId,
    }
    return (await axiosMgmtInstance.delete(`/${organization}/process-items/${id}/node-access`, { data: body })).data
  }

  // Add process user access
  async addUserInOrgAccess(
    organization: string,
    id: string,
    processAccessId: string,
    userIds: string[],
    accessLevel: string | null,
  ) {
    const body = {
      processAccessId,
      userIds,
      accessLevel: accessLevel ? accessLevel : 'All',
    }
    return (await axiosMgmtInstance.post(`/${organization}/process-items/${id}/user-access`, body)).data
  }
  // Remove process node access
  async removeUserInOrgAccess(organization: string, id: string, processAccessId: string, userId: string) {
    const body = {
      processAccessId,
      userId,
    }
    return (await axiosMgmtInstance.delete(`/${organization}/process-items/${id}/user-access`, { data: body })).data
  }

  async changeUserAccess(
    organization: string,
    userId: string,
    itemId: string,
    processAccessId: string,
    accessLevel: string,
  ) {
    const body = {
      processAccessId,
      userId,
      accessLevel,
    }
    return (await axiosMgmtInstance.post(`/${organization}/process-items/${itemId}/change-user-access`, body)).data
  }

  async changeAssigneeCandidate(organization, id, userTaskId, assigneeCandidateType, assigneeCandidateId?) {
    const body = {
      assigneeCandidateId: assigneeCandidateId ?? null,
      assigneeCandidateType,
    }
    return (
      await axiosMgmtInstance.post(`/${organization}/processes/${id}/user-tasks/${userTaskId}/assignee-candidate`, body)
    ).data
  }

  async changeBulkAction(organization, id, userTaskId, bulkCompleteAllowed) {
    const body = {
      bulkCompleteAllowed: bulkCompleteAllowed,
    }
    return (
      await axiosMgmtInstance.post(`/${organization}/processes/${id}/user-tasks/${userTaskId}/bulk-complete`, body)
    ).data
  }
}

export default new ProcessDefinitionsSettings()
