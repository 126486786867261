import React, { FC } from 'react'
import HeaderSearchFilterMobile from './HeaderSearchFilterMobile'
import { useTranslation } from 'react-i18next'
import { ProcessTableHeaders } from '../table/TableProcess'

type HeaderFiltersMobileProps = {
  headers: ProcessTableHeaders[]
  onSearch?: (field: string, operation: string, value: any) => void
  filters: string[]
  headerFilters: any
}

const HeaderFiltersMobile: FC<HeaderFiltersMobileProps> = ({ headers, onSearch, filters, headerFilters }) => {
  const { t } = useTranslation()
  const clearFilters = () => {
    if (!onSearch) return
    headers.forEach((header) => {
      if (['Activity', 'Priority', 'Resolution', 'ActivityAssignee', 'ProcessInstanceStatus'].includes(header.id)) {
        onSearch(
          header.processInstanceField.toLowerCase() === 'activities'
            ? 'activities.activityId'
            : header.scope === 'Activity'
            ? `activities.${header.processInstanceField}`
            : header.scope === 'Variable'
            ? `typedValues.${header.processInstanceField}`
            : header.processInstanceField,
          'in',
          [],
        )
      }
      if (['ActivityAssignee'].includes(header.id)) {
        onSearch?.(
          header.processInstanceField.toLowerCase() === 'activities'
            ? 'activities.activityId'
            : header.scope === 'Activity'
            ? header.processInstanceField === 'assignee'
              ? `activities.assigneeId`
              : `activities.${header.processInstanceField}`
            : header.scope === 'Variable'
            ? `typedValues.${header.processInstanceField}`
            : header.processInstanceField,
          'in',
          [],
        )
        onSearch?.('activities.assigneeType', 'in', [])
      }
    })
    onSearch?.('processInstanceCode|name|description', 'like', '')
  }

  return (
    <div className='relative'>
      <div className='flex flex-row gap-x-1.5 overflow-x-auto pr-4 no-scrollbar items-center'>
        {headers.map((header, index) => {
          if (['Activity', 'Priority', 'Resolution', 'ActivityAssignee', 'ProcessInstanceStatus'].includes(header.id))
            return (
              <HeaderSearchFilterMobile
                header={header}
                onSearch={onSearch}
                filters={filters}
                key={index}
                value={
                  headerFilters?.[
                    header.processInstanceField.toLowerCase() === 'activities'
                      ? 'activities.activityId'
                      : header.scope === 'Activity'
                      ? header.processInstanceField === 'assignee'
                        ? `activities.assigneeId`
                        : `activities.${header.processInstanceField}`
                      : header.scope === 'Variable'
                      ? `typedValues.${header.processInstanceField}`
                      : header.processInstanceField
                  ]
                }
              />
            )
        })}
        {(filters?.length > 1 || filters[0] !== '') && (
          <p className='text-blue-500 whitespace-nowrap mr-3' onClick={() => clearFilters()}>
            {t('ProcessInstance.filters.clearFilters')}
          </p>
        )}
      </div>
      {/* <div className='absolute top-0 left-0 h-full w-8 pointer-events-none bg-gradient-to-r from-white to-transparent'></div> */}
      <div className='absolute top-0 right-0 h-full w-8 pointer-events-none bg-gradient-to-l from-white to-transparent'></div>
    </div>
  )
}

export default HeaderFiltersMobile
