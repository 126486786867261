import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Button, DataField, TabPanel, Tabs } from '@sistemiv/s-components'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from '../../../classNames'
import AssigneeSearchMobile from './AssigneeSearchMobile'

const AssigneeComboboxMobile: FC<{
  options: TableField[]
  value: TableField[]
  loading?: boolean
  onChange?: (value?: TableField[]) => void
}> = ({ options, value, loading, onChange }) => {
  const { t } = useTranslation()
  const tabs = ['Users', 'Groups', 'Org chart']
  const [selectedTab, setSelectedTab] = useState('Users')
  const [openSearch, setOpenSearch] = useState(false)
  const filteredOptions = options

  return (
    <>
      {openSearch && (
        <AssigneeSearchMobile
          options={options}
          value={value}
          loading={loading}
          onChange={onChange}
          setIsOpen={setOpenSearch}
        />
      )}
      <div className='overflow-y-auto'>
        <Combobox
          value={value.map((o) => o.value)}
          onChange={(value) => onChange && onChange(options.filter((f) => value.includes(f.value)))}
          multiple
        >
          <div className='w-full max-h-full overflow-hidden'>
            <Combobox.Options static>
              <div className='flex justify-between items-center'>
                <Button
                  className='text-blue-500 disabled:text-gray-400 whitespace-nowrap'
                  onClick={() => onChange && onChange(filteredOptions)}
                  disabled={value?.length === filteredOptions.length}
                >
                  {t('ProcessInstance.filters.selectAll')}
                </Button>
                <Button
                  className='text-blue-500 disabled:text-gray-400 whitespace-nowrap'
                  onClick={() => onChange && onChange([])}
                  disabled={value?.length === 0}
                >
                  {t('ProcessInstance.filters.clearSelection')}
                </Button>
              </div>
              <div className='w-full relative border-b border-gray-300'>
                <div className='w-full flex items-center px-3 z-0 cursor-pointer' onClick={() => setOpenSearch(true)}>
                  <div className='relative w-full py-2'>
                    <span className='box-border pr-3 pl-8 py-2 w-full border-none focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-sky-100 rounded cursor-pointer text-gray-400'>
                      {t('Common.search')}
                    </span>
                    <span className='absolute inset-y-0 flex items-center pl-2 left-0'>
                      <MagnifyingGlassIcon className='w-4 h-4 text-gray-600 stroke-2' aria-label='true' />
                    </span>
                  </div>
                </div>
              </div>

              <Tabs active={selectedTab} tabs={tabs} onClick={setSelectedTab} className='px-3 [&+div]:!pt-2'>
                <TabPanel active={selectedTab} tab='Users'>
                  {filteredOptions
                    .filter((o) => o.tab === 'Users')
                    .map((o, index) => (
                      <Combobox.Option
                        key={`option-${index}`}
                        value={o.value}
                        className='relative cursor-pointer select-none py-2 px-4 text-gray-900'
                      >
                        {({ selected }) => (
                          <div className='flex items-center justify-start gap-x-4'>
                            <input
                              type='checkbox'
                              readOnly
                              checked={selected}
                              className={classNames(
                                'cursor-pointer h-4.5 w-4.5 text-sky-500 rounded-sm disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0',
                              )}
                            />
                            <DataField
                              field={o}
                              className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[15rem] [&>div>svg]:w-5 [&>div>svg]:h-5'
                            />
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                </TabPanel>
                <TabPanel active={selectedTab} tab='Groups'>
                  {filteredOptions
                    .filter((o) => o.tab === 'Groups')
                    .map((o, index) => (
                      <Combobox.Option
                        key={`option-${index}`}
                        value={o.value}
                        className='relative cursor-pointer select-none py-2 px-4 text-gray-900'
                      >
                        {({ selected }) => (
                          <div className='flex items-center justify-start gap-x-4'>
                            <input
                              type='checkbox'
                              readOnly
                              checked={selected}
                              className={classNames(
                                'cursor-pointer h-4.5 w-4.5 text-sky-500 rounded-sm disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0',
                              )}
                            />
                            <DataField
                              field={o}
                              className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[15rem] [&>div>svg]:w-5 [&>div>svg]:h-5'
                            />
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                </TabPanel>
                <TabPanel active={selectedTab} tab='Org chart'>
                  {filteredOptions
                    .filter((o) => o.tab === 'Org chart')
                    .map((o, index) => (
                      <Combobox.Option
                        key={`option-${index}`}
                        value={o.value}
                        className='relative cursor-pointer select-none py-2 px-4 text-gray-900'
                      >
                        {({ selected }) => (
                          <div className='flex items-center justify-start gap-x-4'>
                            <input
                              type='checkbox'
                              readOnly
                              checked={selected}
                              className={classNames(
                                'cursor-pointer h-4.5 w-4.5 text-sky-500 rounded-sm disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0',
                              )}
                            />
                            <DataField
                              field={o}
                              className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[15rem] [&>div>svg]:w-5 [&>div>svg]:h-5'
                            />
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                </TabPanel>
              </Tabs>
            </Combobox.Options>
          </div>
        </Combobox>
      </div>
    </>
  )
}

export default AssigneeComboboxMobile
