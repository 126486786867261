import { PropsWithClassName } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, PropsWithChildren } from 'react'
import classNames from '../../classNames'
import { useIsMobile } from '../../context/ResponsiveContextProvider'

const LeftCollapseLayout: FC<PropsWithClassName<PropsWithChildren>> = ({ className, children }) => {
  const isMobile = useIsMobile()
  return (
    <div
      className={classNames(
        !isMobile ? 'grid grid-cols-[auto_1fr] grid-rows-[calc(100vh_-_4.185rem)] h-full' : 'h-full',
        className ?? '',
      )}
    >
      {children}
    </div>
  )
}

export default LeftCollapseLayout
