import React, { FC, useCallback, useRef } from 'react'
import TaskItem, { TaskItemProps } from './TaskItem'
import classNames from '../../../classNames'
import { useIsMobile, useNavigationContext } from '../../../context/ResponsiveContextProvider'
import { SpinnerIcon } from '@sistemiv/s-components'

export type TaskListProps = {
  tasks: TaskItemProps[]
  onTaskSelected?: (id: string) => void
  selectedId: string
  hasNextPage: boolean
  isTaskListFetching: boolean
  fetchNextPage: () => void
  isListCollapsed: boolean
}
const TaskList: FC<TaskListProps> = ({
  tasks,
  onTaskSelected,
  selectedId,
  hasNextPage,
  isTaskListFetching,
  fetchNextPage,
  isListCollapsed,
}) => {
  const isMobile = useIsMobile()
  const { hideNavigations } = useNavigationContext()
  const lastScrollY = useRef(0)

  const handleScroll = (e) => {
    const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 2
    if (bottom) {
      if (!hasNextPage || isTaskListFetching) return
      fetchNextPage()
    }

    if (!isMobile) return

    const currentScrollY = e.target.scrollTop

    if (currentScrollY > lastScrollY.current && currentScrollY > 100) {
      hideNavigations && hideNavigations(true)
    } else if (currentScrollY < lastScrollY.current) {
      hideNavigations && hideNavigations(false)
    }

    lastScrollY.current = currentScrollY
  }

  const observerRef = useRef<IntersectionObserver | null>(null)
  const lastTaskRef = useCallback(
    (node) => {
      if (isTaskListFetching) return
      if (observerRef.current) observerRef.current.disconnect()
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage()
        }
      })
      if (node) observerRef.current.observe(node)
    },
    [isTaskListFetching, fetchNextPage, hasNextPage],
  )
  return (
    <div className={classNames(isListCollapsed ? 'hidden' : '', 'h-full overflow-y-auto')} onScroll={handleScroll}>
      <div
        className={classNames('overflow-y-auto', !isMobile ? 'divide-y divide-gray-300' : 'pb-20')}
        data-testid='task-list'
      >
        {tasks.map((task, i) => {
          return <TaskItem key={i} {...task} onSelected={onTaskSelected} selected={selectedId === task.id} />
        })}
      </div>
      <div ref={lastTaskRef}></div>
      {isTaskListFetching && (
        <div className='w-full pt-6 flex justify-center'>
          <SpinnerIcon className='text-sky-500' />
        </div>
      )}
    </div>
  )
}

export default TaskList
