import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Button } from '@sistemiv/s-components'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface ProcessAccessHeaderProps {
  name?: string
  onAdMembers?(): void
}
const ProcessAccessHeader: FC<ProcessAccessHeaderProps> = ({ name, onAdMembers }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className='flex flex-none items-center gap-x-16 border-b border-gray-300 pr-8 pl-4 py-3'>
      <div className='flex items-center gap-x-6'>
        <Button onClick={() => navigate(-1)}>
          <ArrowLeftIcon className='w-5 h-5 text-gray-500' />
        </Button>
        <p className='text-2xl'>{`Processes / ${name ? name : ''}`}</p>
      </div>
      <div className='flex items-center gap-x-4'>
        <Button className='text-white bg-blue-500 border border-blue-500' onClick={onAdMembers}>
          {t('Settings.addUser')}
        </Button>
      </div>
    </div>
  )
}

export default ProcessAccessHeader
