import { Combobox, Transition } from '@headlessui/react'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { FC, Fragment, useState } from 'react'
import { ImgWrapper } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { imageBase } from '../../../../services/http-common'

export interface AccessUser {
  id: string
  name: string
  objectId: string
  photo?: string
}

interface MemberPillComboboxProps {
  options: AccessUser[]
  value: AccessUser[]
  onChange?: (value: any) => void
  label?: string
}
const MemberPillCombobox: FC<MemberPillComboboxProps> = ({ value, onChange, options, label }) => {
  const [query, setQuery] = useState('')
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [isDropDownListOpened, setIsDropDownListOpened] = useState(false)
  const { t } = useTranslation()

  const filteredOptions =
    query === ''
      ? options.filter((o) => !value.map((v) => v.objectId).includes(o.objectId))
      : options
          .filter((o) => !value.map((v) => v.objectId).includes(o.objectId))
          .filter((o) => o.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')))

  return (
    <Combobox
      onChange={(value) => {
        inputRef.current?.focus()
        onChange && onChange(value)
        setQuery('')
      }}
      value={value}
      multiple
    >
      <div className='relative'>
        <div className='relative w-full'>
          <div className='flex items-center gap-x-3 gap-y-2 flex-wrap border rounded-md border-gray-300 px-3 py-2 focus-within:border-sky-100 focus-within:ring-2 focus-within:ring-sky-100'>
            {value?.map((member) => (
              <div
                key={member.id}
                className='flex items-center gap-x-2 rounded-full py-1 px-2 bg-gray-200 text-slate-500 text-sm leading-4'
              >
                <div className='flex items-center gap-x-2'>
                  <ImgWrapper
                    className='w-6 h-6 rounded-full'
                    src={`${imageBase}/${member?.objectId}/32`}
                    onErrorComponent={
                      <p className='text-white bg-[#7b87ae] font-semibold rounded-full p-1.5 flex items-center justify-center w-6 h-6'>
                        {member.name.charAt(0).toUpperCase()}
                      </p>
                    }
                  />
                  {member.name}
                </div>
                <button className='flex items-center justify-center'>
                  <XMarkIcon
                    className='w-4 h-4'
                    onClick={() => {
                      onChange?.(value.filter((v) => v.objectId !== member.objectId))
                    }}
                  />
                </button>
              </div>
            ))}
            <Combobox.Input
              ref={inputRef}
              value={query}
              placeholder={value.length === 0 ? (label ? label : t('AccessModal.addUsers')) : ''}
              onFocus={() => {
                setIsDropDownListOpened(true)
              }}
              onBlur={() => {
                setIsDropDownListOpened(false)
              }}
              onChange={(e) => {
                setQuery(e.target.value)
                inputRef.current?.focus()
              }}
              className={`w-full shrink relative border-none py-0 px-1 focus:border-none focus:ring-0
                ${value.length === 0 ? '!pl-5' : ''}`}
            />
            <Combobox.Button
              className={`absolute inset-y-0 flex items-center justify-center left-0 pl-2
                ${value.length === 0 ? 'flex' : 'hidden'}`}
            >
              <PlusIcon className='w-4 h-4 text-gray-500' />
            </Combobox.Button>
          </div>
        </div>
        <Transition
          as={Fragment}
          show={isDropDownListOpened}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className='absolute overflow-auto mt-1 z-10 min-w-sm max-h-60 w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 bg-white'>
            {filteredOptions.length === 0 ? (
              <div className='py-2 px-3 cursor-default select-none text-gray-700'>{t('Common.nothingFound')}</div>
            ) : (
              filteredOptions?.map((option) => (
                <Combobox.Option
                  key={option.id}
                  value={option}
                  className='py-2 px-3 cursor-pointer select-none hover:bg-slate-100'
                  id={`option-${option.id}`}
                >
                  <div className='flex items-center gap-x-2' id={option.id}>
                    <ImgWrapper
                      className='w-6 h-6 rounded-full'
                      src={`${imageBase}/${option?.objectId}/32`}
                      onErrorComponent={
                        <p className='text-white bg-[#7b87ae] font-semibold rounded-full p-1.5 flex items-center justify-center w-6 h-6'>
                          {option.name.charAt(0).toUpperCase()}
                        </p>
                      }
                    />

                    {option.name}
                  </div>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

export default MemberPillCombobox
