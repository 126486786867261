import { MobileModalWithButton, SortIcon, SortIconFlipped } from '@sistemiv/s-components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

type InstanceSorterMobileProps = {
  sortField: string
  setSortField: (value: React.SetStateAction<string>) => void
  options: { id: string; name: string }[]
  order: 'asc' | 'desc'
  setOrder: (value: React.SetStateAction<string>) => void
}
const InstanceSorterMobile: FC<InstanceSorterMobileProps> = ({ sortField, setSortField, options, order, setOrder }) => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-row items-center'>
      <MobileModalWithButton
        buttonContent={
          <span className='text-gray-600 pl-3 my-3'>
            {options.find((o) => o.id === sortField)?.name || t('ProcessInstance.filters.sortBy')}
          </span>
        }
        leftLabel={t('ProcessInstance.filters.sortBy') as string}
      >
        {({ closeModal }) => (
          <>
            {options.map((option) => (
              <div key={option.id}>
                <div
                  onClick={() => {
                    options.find((o) => o.id === sortField) === option ? setSortField('') : setSortField(option.id)
                    closeModal()
                  }}
                  className='cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0 flex items-center justify-between'
                >
                  <span>{option.name}</span>
                  <span className='ml-5'>
                    {option.id === sortField ? (
                      order === 'desc' ? (
                        <SortIconFlipped className='w-4 h-4 fill-gray-400' />
                      ) : (
                        <SortIcon className='w-4 h-4 fill-gray-400' />
                      )
                    ) : null}
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </MobileModalWithButton>
      <span className='ml-5' onClick={() => setOrder && setOrder(order === 'asc' ? 'desc' : 'asc')}>
        {order === 'desc' ? (
          <SortIconFlipped className='w-4 h-4 fill-gray-400' />
        ) : (
          <SortIcon className='w-4 h-4 fill-gray-400' />
        )}
      </span>
    </div>
  )
}

export default InstanceSorterMobile
