import React, { FC } from 'react'

type IncidentsReportFieldProps = {
  label: string
  text: string
}

const IncidentReportField: FC<IncidentsReportFieldProps> = ({ label, text }) => {
  return (
    <div className='flex flex-row items-center py-1 max-w-full'>
      <div className=' w-[90px] pr-4'>
        <h1 className='text-[#8087A0] text-sm'>{label}</h1>
      </div>
      <h1 className='text-[#26282F] text-sm text-wrap break-words max-w-full'>{text}</h1>
      <p></p>
    </div>
  )
}

export default IncidentReportField
