import { useInfiniteQuery } from '@tanstack/react-query'
import ProcessInstancesService from '../../services/ProcessInstances.service'
import { useParams } from 'react-router-dom'

type InstancesPaginationOptions = {
  params?: any
  enabled?: boolean
  size: number
}

export const useProcessInstancesPagination = ({ params, enabled = true, size = 16 }: InstancesPaginationOptions) => {
  const { org, type } = useParams()
  return useInfiniteQuery<any>({
    queryKey: ['process-instances-paginated', org, type, { size, ...params }],
    queryFn: ({ pageParam = 1 }) => {
      return ProcessInstancesService.list(org!, type!, pageParam as number, size, params)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (!lastPage?.results?.length) return undefined
      const nextPage = lastPage.page !== undefined ? lastPage.page + 2 : undefined
      return nextPage
    },
    staleTime: 0,
    enabled: !!org && !!type && enabled,
  })
}
