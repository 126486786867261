import { Dialog, Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button, ModalDialog } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, Fragment, useState } from 'react'
import MemberPillCombobox, { AccessUser } from './MemberPillCombobox'
import { useTranslation } from 'react-i18next'
import { imageBase } from '../../../../services/http-common'
import { accessLevels } from './ProcessAccessPage'

interface AddUserAccessModalProps {
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onAdd?(value: string[], accessLevel: string): void
  data?: any[]
  isLoading: boolean
  usersInOrg: any[]
}

const AddUserAccessModal: FC<AddUserAccessModalProps> = ({ open, setOpen, onAdd, data, usersInOrg, isLoading }) => {
  const [selectedMembers, setSelectedMembers] = useState<AccessUser[]>([])
  const { t } = useTranslation()
  const [accessLevel, setAccessLevel] = useState(accessLevels[0])

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-lg'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {t('AccessModal.addUsers')}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>

      <div className='pt-5 space-y-6'>
        <div className='flex flex-col justify-center gap-y-2  w-full'>
          <p className='text-gray-400 text-md'> {t('AccessModal.users')}</p>
          <MemberPillCombobox
            options={usersInOrg
              .filter((user) => !data?.some((_user) => _user.id === user.id))
              .map((user) => ({
                id: user.id,
                objectId: user.objectId,
                name: user.name,
                photo: `${imageBase}/${user.objectId}/76`,
              }))}
            value={selectedMembers}
            onChange={setSelectedMembers}
          />
        </div>
        <div className='flex flex-col gap-y-2 justify-center'>
          <p className='text-gray-400 text-md'> {t('AccessModal.seeInstances')}</p>
          <Listbox value={accessLevel} onChange={setAccessLevel}>
            <div className='relative w-1/2'>
              <Listbox.Button className='relative w-full px-3 py-2 cursor-default rounded-md pr-10 text-left border border-gray-300'>
                {accessLevel.name}
                <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
                  <ChevronDownIcon className='w-5 h-5 text-gray-700 cursor-pointer' aria-hidden='true' />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options className='absolute z-10 mt-1 right-0 min-w-[12rem] rounded-md max-h-60 w-fit overflow-auto bg-white py-3 drop-shadow-lg shadow-xl ring-1 ring-black ring-opacity-5'>
                  {accessLevels
                    .filter((l) => l.id !== accessLevel.id)
                    .map((level) => (
                      <Listbox.Option
                        className='w-full cursor-pointer py-2 px-4 hover:bg-slate-100'
                        key={level.id}
                        value={level}
                      >
                        {level.name}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>

        <div className='flex items-center justify-end gap-x-3'>
          <Button className='hover:bg-gray-100 active:text-blue-500' onClick={() => setOpen(false)}>
            {t('Common.cancel')}
          </Button>
          <Button
            className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
            onClick={() =>
              onAdd &&
              onAdd(
                selectedMembers?.map((m) => m.objectId),
                accessLevel.name,
              )
            }
            disabled={selectedMembers.length === 0}
            loading={isLoading}
          >
            {t('Common.add')}
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default AddUserAccessModal
