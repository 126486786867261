import React from 'react'
import { mapStatusToIcon, ProcessTableRow } from '../table/TableProcess'
import { ClockIcon } from '@sistemiv/s-components'
import dayjs from 'dayjs'
import classNames from '../../../classNames'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

type Props = {
  row: ProcessTableRow
  selectable: boolean
  selectedRows?: ProcessTableRow[]
  setSelectedRows?: (value: React.SetStateAction<ProcessTableRow[]>) => void
  processColor?: string
  onRowClick?: (id: string) => void
  handleAddToHistory?: () => void
}

const ProcessInstanceItemMobile = ({
  row,
  selectable,
  selectedRows,
  setSelectedRows,
  processColor,
  onRowClick,
  handleAddToHistory,
}: Props) => {
  const handleClick = () => {
    handleAddToHistory?.()
    //dodat delay da bi se setovalo u local storage, zasto to mora ne znam???
    setTimeout(() => {
      onRowClick?.(row.id.value)
    })
  }
  return (
    <div
      key={row.id.value}
      className={classNames(
        'flex items-center justify-between p-3 w-full max-w-full',
        selectable && selectedRows?.find((selected) => selected.id.value === row.id.value) ? 'bg-sky-100' : '',
      )}
    >
      <div className='flex space-x-3 w-full max-w-full overflow-x-hidden'>
        {selectable ? (
          <input
            type='checkbox'
            checked={!!selectedRows?.find((selected) => selected.id.value === row.id.value)}
            onChange={() => {
              if (selectedRows?.find((selected) => selected.id.value === row.id.value)) {
                setSelectedRows?.(selectedRows.filter((selected) => selected.id.value !== row.id.value))
              } else {
                setSelectedRows?.([...(selectedRows || []), row])
              }
            }}
            className={`form-checkbox mt-1 rounded border-none outline-none focus:outline-none focus-visible:outline-none focus:ring-0`}
            style={{ backgroundColor: processColor || '#CAD1F8' }}
          />
        ) : (
          <input
            type='checkbox'
            checked={false}
            disabled={true}
            className={`form-checkbox mt-1 rounded border-none outline-none focus:outline-none focus-visible:outline-none focus:ring-0`}
            style={{ backgroundColor: processColor || '#CAD1F8' }}
          />
        )}

        <div className='flex flex-col gap-y-1 w-full max-w-full' onClick={handleClick}>
          <div
            className={classNames(
              'flex flex-row items-center justify-between',
              row.incidents?.value[0] ? 'text-pink-600' : '',
            )}
          >
            <div className='text-sm flex flex-row gap-x-1 items-center'>
              <span>{row.ProcessInstanceCode.value}</span>

              {!!row.incidents.value.length && (
                <>
                  <ExclamationCircleIcon className='w-5 h-5' />{' '}
                </>
              )}
            </div>
            {row.DueDate.value && (
              <div
                className={classNames(
                  'flex flex-row gap-x-1.5 items-center text-gray-400',
                  row.DueDate?.value && dayjs().isAfter(new Date(row.DueDate?.value)) ? '!text-rose-600' : '',
                )}
              >
                <ClockIcon className='w-4 h-4 fill-gray-400' />
                <p className='text-sm'>{dueTime(new Date(row.DueDate?.value))}</p>
              </div>
            )}
          </div>
          <div className='flex flex-row justify-between items-center gap-x-2 overflow-hidden'>
            {/* <p className='text-sm text-gray-400'>{row.ProcessInstanceStatus.value}</p> */}
            {mapStatusToIcon(row.ProcessInstanceStatus.value)}
            <div className='flex flex-row gap-x-1 items-center min-w-0 flex-1'>
              {Array.isArray(row.Activity?.value) && row.Activity.value[0] && (
                <div
                  className='rounded px-1 text-sm overflow-hidden text-ellipsis whitespace-nowrap flex-shrink-0 min-w-0 max-w-[170px]'
                  style={{ backgroundColor: row.Activity?.solidColor }}
                >
                  {row.Activity.value[0]}
                </div>
              )}
              {Array.isArray(row.Activity?.value) && row.Activity.value.length > 1 && (
                <div className='rounded-full text-white bg-sky-400 px-1 text-sm whitespace-nowrap flex-shrink-0'>
                  {`+ ${row.Activity.value.length - 1}`}
                </div>
              )}
              {row.Resolution?.value &&
                (!row.incidents?.value[0] ? (
                  <div className='text-sm text-green-500 border border-green-500 rounded px-1 w-fit whitespace-nowrap'>
                    {row.Resolution.value}
                  </div>
                ) : (
                  <div className='text-sm text-gray-500 border border-gray-500 rounded px-1 w-fit whitespace-nowrap'>
                    {row.Resolution.value}
                  </div>
                ))}
            </div>
            <span className='flex-1 text-sm text-slate-600 whitespace-nowrap overflow-hidden text-ellipsis text-right'>
              {row.Name.value}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcessInstanceItemMobile

const dueTime = (date?: Date): string => {
  const dayjsDate = dayjs(date)
  const now = dayjs()
  if (!date) return ''
  return Math.abs(dayjsDate.diff(now, 'year')) > 0
    ? `${Math.abs(dayjsDate.diff(now, 'year'))}y`
    : Math.abs(dayjsDate.diff(now, 'month')) > 0
    ? `${Math.abs(dayjsDate.diff(now, 'month'))}mo`
    : Math.abs(dayjsDate.diff(now, 'week')) > 0
    ? `${Math.abs(dayjsDate.diff(now, 'week'))}w`
    : Math.abs(dayjsDate.diff(now, 'day')) > 0
    ? `${Math.abs(dayjsDate.diff(now, 'day'))}d`
    : `${Math.abs(dayjsDate.diff(now, 'hour'))}h`
}
