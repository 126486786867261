import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import classNames from '../../../../classNames'
import { EmptyState, MobileModalWithButton } from '@sistemiv/s-components'

type DetailsTabMobileProps = {
  activities: any[]
  incidents: any[]
  selectedId?: string
  onClick?: (id: string) => void
}

const DetailsTabMobile: FC<DetailsTabMobileProps> = ({ activities, incidents, selectedId, onClick }) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (activities.length > 0) {
      onClick?.(activities[0].activityId)
    }
  }, [activities, onClick])

  return (
    <div className='flex flex-col gap-y-3'>
      {activities?.length > 0 ? (
        <MobileModalWithButton
          buttonContent={
            <>
              <div
                className={classNames(
                  'rounded flex items-start p-0.5 px-2 min-w-fit',
                  incidents
                    ?.map((i) => i.activityInstanceId)
                    .includes(activities.find((a) => a.activityId === selectedId)?.activityInstanceId)
                    ? 'bg-pink-50'
                    : 'bg-[#DEE1FC]',
                )}
              >
                {activities.find((a) => a.activityId === selectedId)?.activityName}
              </div>
              {activities.length > 1 && <ChevronDownIcon className='w-5 h-5 stroke-2 text-gray-700 mr-1' />}
            </>
          }
          className='w-full relative flex flex-row items-center border bg-white p-1 rounded-md justify-between'
          disabled={activities.length === 1}
        >
          {({ closeModal }) => (
            <>
              {activities.map((activity) => (
                <div key={activity.activityId}>
                  <div
                    className='w-full py-2'
                    onClick={() => {
                      onClick?.(activity.activityId)
                      closeModal()
                    }}
                  >
                    <span className='mr-5'>
                      <span className='absolute'>
                        {activity.activityId === selectedId && <CheckIcon className='h-5 w-5' aria-hidden='true' />}
                      </span>
                    </span>
                    <span
                      className={classNames(
                        'rounded p-0.5 px-2 max-w-fit ml-2',
                        incidents?.map((i) => i.activityInstanceId).includes(activity.activityInstanceId)
                          ? 'bg-pink-50'
                          : 'bg-[#DEE1FC]',
                      )}
                    >
                      {activity.activityName}
                    </span>
                  </div>
                </div>
              ))}
            </>
          )}
        </MobileModalWithButton>
      ) : (
        <EmptyState emptyText={t('ProcessInstance.noCurrentActivities')} />
      )}
    </div>
  )
}

export default DetailsTabMobile
