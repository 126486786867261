import { Dialog, Transition } from '@headlessui/react'
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { PrinterIcon } from '@heroicons/react/24/solid'
import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react'
import DownloadIcon from '../../../assets/icons/DownloadIcon'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { Button } from '@sistemiv/s-components'
import { Item } from './AttachmentsTab'
import { useTranslation } from 'react-i18next'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type AttachmentPreviewModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  currentItem: Item
  onNextItemClick?: () => void
  onPreviousItemClick?: () => void
  onExitClick?: () => void
  onPrintClick?: (item: Item) => void
  onDownloadClick?: (id: string) => void
}
const height = window.innerHeight - 10
const AttachmentPreviewModal: FC<AttachmentPreviewModalProps> = ({
  open,
  setOpen,
  currentItem,
  onPreviousItemClick,
  onNextItemClick,
  onExitClick,
  onPrintClick,
  onDownloadClick,
}) => {
  const [zoom, setZoom] = useState(1)
  const [numPages, setNumPages] = useState(0)
  const { t } = useTranslation()

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  }

  useEffect(() => {
    !open && setZoom(1)
  }, [open])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-slate-900/80' />
        </Transition.Child>
        <div className='fixed inset-0'>
          <div className='flex h-full min-h-full items-center justify-center text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='relative w-full h-full transform bg-transparent text-left align-middle transition-all'>
                <Dialog.Title className='z-20 fixed top-0 w-full bg-gradient-to-b from-slate-900 p-8 flex items-center justify-between text-white'>
                  <div className='flex items-center gap-x-3'>
                    <Button onClick={() => setOpen(false)} className='!p-1.5 rounded-full hover:bg-blue-600'>
                      <ArrowLeftIcon className='w-5 h-5' />
                    </Button>
                  </div>
                  <div className='flex items-center gap-x-2'>
                    {onPrintClick && (
                      <Button
                        onClick={() => onPrintClick(currentItem)}
                        className='!p-1.5 rounded-full hover:bg-blue-600'
                      >
                        <PrinterIcon className='w-5 h-5' />
                      </Button>
                    )}
                    {onDownloadClick && (
                      <Button
                        onClick={() => onDownloadClick(currentItem.id)}
                        className='!p-1.5 rounded-full hover:bg-blue-600'
                      >
                        <DownloadIcon className='w-5 h-5 fill-white' />
                      </Button>
                    )}

                    <Button onClick={onExitClick} className='!p-1.5 rounded-full hover:bg-blue-600'>
                      <XMarkIcon className='w-5 h-5' />
                    </Button>
                  </div>
                </Dialog.Title>

                {onPreviousItemClick && (
                  <div className='fixed left-0 inset-y-0 z-10 p-6 inline-flex justify-center items-center text-white'>
                    <Button
                      className='!p-1.5 rounded-full bg-slate-700/50 hover:bg-blue-600'
                      onClick={onPreviousItemClick}
                    >
                      <ChevronLeftIcon className='w-5 h-5' />
                    </Button>
                  </div>
                )}
                <div className='w-full h-full overflow-hidden'>
                  {currentItem && currentItem.mimeType?.includes('image') && (
                    <div
                      className='w-full h-full overflow-auto inline-flex items-center justify-center '
                      data-testid='gallery-item'
                    >
                      <img src={currentItem.url} style={{ transform: `scale(${zoom})` }} />
                    </div>
                  )}
                  {currentItem && currentItem.fileName.includes('.pdf') && (
                    <div className='flex items-center justify-center h-full'>
                      <Document
                        file={currentItem.url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={console.error}
                        className='h-full overflow-auto'
                        options={options}
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            scale={zoom}
                            renderAnnotationLayer={true}
                            renderTextLayer={true}
                            height={height}
                            className='mb-4'
                          />
                        ))}
                      </Document>
                    </div>
                  )}
                  {currentItem && !currentItem.fileName.includes('.pdf') && !currentItem.fileName.includes('.png') && (
                    <div className='flex items-center justify-center h-full text-white text-2xl'>
                      {t('ProcessInstance.attachments.noPreview')}
                    </div>
                  )}
                </div>
                {onNextItemClick && (
                  <div className='fixed inset-y-0 z-10 right-0 p-6 inline-flex justify-center items-center text-white'>
                    <Button className='!p-1.5 rounded-full bg-slate-700/50 hover:bg-blue-600' onClick={onNextItemClick}>
                      <ChevronRightIcon className='w-5 h-5' />
                    </Button>
                  </div>
                )}
                <div className='fixed w-full bottom-0 z-20 flex justify-center items-center p-8 text-white gap-x-3'>
                  <Button
                    className='!p-1.5 rounded-full bg-slate-700/50 disabled:text-gray-400 disabled:bg-transparent hover:bg-blue-600'
                    onClick={() => setZoom(zoom - 1)}
                    disabled={zoom <= 1}
                  >
                    <div data-testid='zoom-1'>
                      <MagnifyingGlassMinusIcon className='w-5 h-5' />
                    </div>
                  </Button>
                  <Button
                    className='!p-1.5 rounded-full bg-slate-700/50 disabled:text-gray-400 disabled:bg-transparent hover:bg-blue-600'
                    onClick={() => setZoom(zoom + 1)}
                    disabled={zoom >= 10}
                  >
                    <div data-testid='zoom+1'>
                      <MagnifyingGlassPlusIcon className='w-5 h-5' />
                    </div>
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default AttachmentPreviewModal
