import React, { FC, useEffect, useMemo, useState } from 'react'
import { ProcessTableHeaders } from '../table/TableProcess'
import { useParams } from 'react-router-dom'
import { useProcessInstances } from '../../../repositories'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { Combobox } from '@headlessui/react'
import { t } from 'i18next'
import { Button, DataField, MobileModalWithButton, SideMenuParentArrowIcon, SpinnerIcon } from '@sistemiv/s-components'
import classNames from '../../../classNames'
import { AssigneeCandidateType } from '../../../models/ProcessInstance'
import useAssigneeOptions from '../../../hooks/useAssigneeOptions'
import AssigneeComboboxMobile from './AssigneeComboboxMobile'

const HeaderSearchFilterMobile: FC<{
  onSearch?(id: string, option: string, value: any): void
  header: ProcessTableHeaders
  filters?: string[]
  value: any
}> = ({ onSearch, header, filters, value }) => {
  const { org } = useParams()
  //postoje 4 mogucnosti filtera, assignee opcija 1
  const [assigneeOptions, setAssigneeOptions] = useState<any>([])
  const [assigneeIsOpen, setAssigneeIsOpen] = useState(false)
  const { users, groups, orgs, fetchData: fetchAssigneeOptions } = useAssigneeOptions(org)
  const [selectOpen, setSelectOpen] = useState(false)
  const [isAssigneeLoading, setIsAssigneeLoading] = useState(false)

  useEffect(() => {
    if (header.searchType !== 'assignee') return
    if (!org || !assigneeIsOpen) return
    setIsAssigneeLoading(true)
    fetchAssigneeOptions().finally(() => setIsAssigneeLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org, header.searchType, setIsAssigneeLoading, assigneeIsOpen])

  useEffect(() => {
    setAssigneeOptions([...users, ...groups, ...orgs])
  }, [groups, users, orgs])
  ////
  const [size, setSize] = useState(20)

  const {
    data: optionsRaw,
    isLoading,
    isFetching,
  } = useProcessInstances({
    page: 1,
    size,
    params: {
      select:
        header.scope === 'Activity' && header.processInstanceField.toLowerCase() !== 'activities'
          ? `activities.${header.processInstanceField}`
          : header.scope === 'Variable'
          ? `typedValues.${header.processInstanceField}`
          : `${header.processInstanceField}`,
      filters,
    },
    enabled: header.searchType?.includes('select') && (value?.value?.length > 0 || selectOpen),
  })

  useEffect(() => {
    if (!optionsRaw) return
    if (optionsRaw.total > size) {
      setSize(optionsRaw.total)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsRaw])
  const priorityMap = (p) => {
    switch (p) {
      case 0:
        return 'Lowest'
      case 25:
        return 'Low'
      case 50:
        return 'Medium'
      case 75:
        return 'High'
      case 100:
        return 'Highest'
      default:
        return ''
    }
  }

  const options: TableField[] = useMemo(() => {
    return header.scope === 'Activity' && header.processInstanceField !== 'activities'
      ? optionsRaw && optionsRaw.values?.[`activities.${header.processInstanceField}`]
        ? Object.keys(optionsRaw?.values?.[`activities.${header.processInstanceField}`]).map((key) => {
            return {
              id: key,
              value: header.id.toLowerCase().includes('priority')
                ? priorityMap(optionsRaw?.values?.[`activities.${header.processInstanceField}`]?.[key])
                : optionsRaw?.values?.[`activities.${header.processInstanceField}`]?.[key],
              solidColor: '#DEE1FC',
              type: header.id.toLowerCase().includes('priority')
                ? 'priority_select'
                : header.processInstanceField.toLowerCase().includes('activities')
                ? 'solid'
                : 'value',
            }
          }) ?? []
        : []
      : optionsRaw && optionsRaw.values?.[header.processInstanceField]
      ? Object.keys(optionsRaw?.values?.[header.processInstanceField]).map((key) => {
          return {
            id: key,
            value: header.id.includes('Priority')
              ? priorityMap(optionsRaw?.values?.[header?.processInstanceField]?.[key])
              : optionsRaw?.values?.[header?.processInstanceField]?.[key],
            solidColor: '#DEE1FC',
            type: header.id.toLowerCase().includes('priority')
              ? 'priority_select'
              : header.processInstanceField.toLowerCase().includes('activities')
              ? 'solid'
              : 'value',
          }
        }) ?? []
      : []
  }, [optionsRaw, header])

  const selectedValue = options.filter((f) => value?.value.includes(f.id)) ?? []
  const onChange = (e) =>
    onSearch?.(
      header.processInstanceField.toLowerCase() === 'activities'
        ? 'activities.activityId'
        : header.scope === 'Activity'
        ? `activities.${header.processInstanceField}`
        : header.scope === 'Variable'
        ? `typedValues.${header.processInstanceField}`
        : header.processInstanceField,
      'in',
      e?.map((v) => v.id),
    )

  return header.searchType === 'select' ? (
    <MobileModalWithButton
      className={classNames(
        'flex flex-row items-center text-sm rounded-full border p-1 px-2 gap-x-1 text-gray-400 !border-gray-400 font-medium',
        selectedValue?.length > 0 ? 'text-white bg-gray-600' : '',
      )}
      buttonContent={
        <>
          <span className='flex gap-x-2 text-sm'>
            {selectedValue?.length > 0 ? (
              <>
                {header.id == 'Activity' ? (
                  <span className='overflow-hidden whitespace-nowrap max-w-[8rem]'>{selectedValue[0].value}</span>
                ) : (
                  <DataField field={selectedValue[0]} className='overflow-hidden whitespace-nowrap max-w-[8rem]' />
                )}
              </>
            ) : (
              <p className='whitespace-nowrap'>{t(`ProcessInstance.filters.${header.id}`)}</p>
            )}
            {selectedValue?.length > 1 && (
              <div className='rounded-full text-white bg-sky-400 px-1 py-0'>+{selectedValue?.length - 1}</div>
            )}
          </span>
          <span className=''>
            {selectedValue?.length === 0 ? (
              <SideMenuParentArrowIcon className='w-3 h-3 fill-gray-400 rotate-90' />
            ) : (
              <SideMenuParentArrowIcon className='w-3 h-3 fill-white rotate-90' />
            )}
          </span>
        </>
      }
      leftLabel={t(`ProcessInstance.filters.${header.id}`) as string}
    >
      {({ closeModal, isOpen }) => {
        if (isOpen && !selectOpen) setSelectOpen(true)
        return (
          <Combobox
            value={selectedValue.map((o) => o.value)}
            onChange={(value1) => onChange && onChange(options.filter((f) => value1.includes(f.value)))}
            multiple
          >
            <div className='relative'>
              {/* Loader */}
              {(isLoading || isFetching) && (
                <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10'>
                  <SpinnerIcon className='w-6 h-6 text-sky-500 animate-spin' />
                </div>
              )}

              {/* Options */}
              <Combobox.Options
                className={classNames(
                  'max-h-60 w-full py-1 text-base focus:outline-none sm:text-sm',
                  isLoading || isFetching ? 'opacity-50' : 'opacity-100',
                )}
                static
              >
                <div className='flex justify-between items-center'>
                  <div>
                    <Button
                      className='text-blue-500 disabled:text-gray-400 whitespace-nowrap'
                      onClick={() => {
                        onChange && onChange(options)
                        closeModal()
                      }}
                      disabled={selectedValue?.length === options.length}
                    >
                      {t('ProcessInstance.filters.selectAll')}
                    </Button>
                  </div>
                  <div>
                    <Button
                      className='text-blue-500 disabled:text-gray-400 whitespace-nowrap'
                      onClick={() => {
                        onChange && onChange([])
                        closeModal()
                      }}
                      disabled={selectedValue?.length === 0}
                    >
                      {t('ProcessInstance.filters.clearSelection')}
                    </Button>
                  </div>
                </div>
                {options.length === 0 ? (
                  <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                    {t('ProcessInstance.filters.nothingFound')}
                  </div>
                ) : (
                  options?.map((option, index) => (
                    <Combobox.Option
                      key={`option-${index}`}
                      value={option.value}
                      className='relative cursor-pointer select-none py-2 px-4 text-gray-900'
                    >
                      {({ selected }) => (
                        <div className='flex items-center justify-start gap-x-4'>
                          <input
                            type='checkbox'
                            readOnly
                            checked={selected}
                            className={classNames(
                              'cursor-pointer h-4.5 w-4.5 text-sky-500 rounded-sm disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0',
                            )}
                          />
                          <DataField
                            field={option}
                            className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[15rem] [&>div>svg]:w-5 [&>div>svg]:h-5'
                          />
                        </div>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </div>
          </Combobox>
        )
      }}
    </MobileModalWithButton>
  ) : header.searchType === 'assignee' ? (
    <MobileModalWithButton
      className={classNames(
        'flex flex-row items-center text-sm rounded-full border p-1 px-2 gap-x-1 text-gray-400 !border-gray-400 font-medium',
        value?.value?.length > 0 ? 'text-white bg-gray-600' : '',
      )}
      buttonContent={
        <>
          <span className='flex gap-x-2 text-sm'>
            {value?.value?.length > 0 ? (
              <>
                <DataField
                  field={assigneeOptions.find((a) => value?.value.includes(a.id))}
                  className='overflow-hidden whitespace-nowrap max-w-[8rem] max-h-5'
                />
              </>
            ) : (
              <p className='whitespace-nowrap'>{t(`ProcessInstance.filters.${header.id}`)}</p>
            )}
            {value?.value?.length > 1 && (
              <div className='rounded-full text-white bg-sky-400 px-1 py-0'>+{value?.value?.length - 1}</div>
            )}
          </span>
          <span className=''>
            {!value?.value?.length ? (
              <SideMenuParentArrowIcon className='w-3 h-3 fill-gray-400 rotate-90' />
            ) : (
              <SideMenuParentArrowIcon className='w-3 h-3 fill-white rotate-90' />
            )}
          </span>
        </>
      }
      leftLabel={t(`ProcessInstance.filters.${header.id}`) as string}
    >
      {({ isOpen }) => {
        if (isOpen && !assigneeIsOpen) setAssigneeIsOpen(true)
        return (
          <AssigneeComboboxMobile
            options={assigneeOptions}
            value={assigneeOptions.filter((f) => value?.value.includes(f.id)) ?? []}
            onChange={(v) => {
              onSearch?.(
                header.processInstanceField.toLowerCase() === 'activities'
                  ? 'activities.activityId'
                  : header.scope === 'Activity'
                  ? header.processInstanceField === 'assignee'
                    ? `activities.assigneeId`
                    : `activities.${header.processInstanceField}`
                  : header.scope === 'Variable'
                  ? `typedValues.${header.processInstanceField}`
                  : header.processInstanceField,
                'in',
                v?.map((a) => a.id) ?? [],
              )
              onSearch?.(
                'activities.assigneeType',
                'in',
                v?.[0]?.tab === 'Users'
                  ? [AssigneeCandidateType.User, AssigneeCandidateType.InstanceStarter]
                  : v?.[0]?.tab === 'Groups'
                  ? [AssigneeCandidateType.Group]
                  : v?.[0]?.tab === 'Org chart'
                  ? [AssigneeCandidateType.OrganizationNode, AssigneeCandidateType.InstanceStarterNode]
                  : '',
              )
            }}
            loading={isAssigneeLoading}
          />
        )
      }}
    </MobileModalWithButton>
  ) : (
    // <AssigneeSearchMobile
    //   isOpen={assigneeIsOpen}
    //   setIsOpen={setAssigneeIsOpen}
    //   options={assigneeOptions}
    //   value={assigneeOptions.filter((f) => value?.value.includes(f.id)) ?? []}
    //   onChange={(v) => {
    //     onSearch?.(
    //       header.processInstanceField.toLowerCase() === 'activities'
    //         ? 'activities.activityId'
    //         : header.scope === 'Activity'
    //         ? header.processInstanceField === 'assignee'
    //           ? `activities.assigneeId`
    //           : `activities.${header.processInstanceField}`
    //         : header.scope === 'Variable'
    //         ? `typedValues.${header.processInstanceField}`
    //         : header.processInstanceField,
    //       'in',
    //       v?.map((a) => a.id) ?? [],
    //     )
    //     onSearch?.(
    //       'activities.assigneeType',
    //       'in',
    //       v?.[0]?.tab === 'Users'
    //         ? [AssigneeCandidateType.User, AssigneeCandidateType.InstanceStarter]
    //         : v?.[0]?.tab === 'Groups'
    //         ? [AssigneeCandidateType.Group]
    //         : v?.[0]?.tab === 'Org chart'
    //         ? [AssigneeCandidateType.OrganizationNode, AssigneeCandidateType.InstanceStarterNode]
    //         : '',
    //     )
    //   }}
    //   assignee={true}
    //   loading={isAssigneeLoading}
    // />
    <div></div>
  )
}

export default HeaderSearchFilterMobile
