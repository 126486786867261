import React, { FC, PropsWithChildren } from 'react'

type IncidentReportWrapperProps = {
  label: string
  className?: string
}

const IncidentReportWrapper: FC<PropsWithChildren<IncidentReportWrapperProps>> = ({ children, label, className }) => {
  return (
    <div className='flex flex-col  items-between gap-1.5 px-2 py-1.5'>
      <h1 className='text-[#8087A0] text-sm '>{label}</h1>
      <div className={`${className ? className : ''} mt-1.5 flex flex-row items-center`}>{children}</div>
    </div>
  )
}

export default IncidentReportWrapper
