import { Button, SpinnerIcon } from '@sistemiv/s-components'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { EyeIcon, EyeSlashIcon, PlusIcon } from '@heroicons/react/24/outline'
import ProcessesConfiguration from './ProcessesConfiguration'
import { Input } from '@sistemiv/s-components'
import ProcessDefinitionsSettings from '../../../services/ProcessDefinitionsSettings'
import { ProcessDefinitionResponse } from '../../../models/ProcessDefinitionSettings'
import { useParams } from 'react-router-dom'
import UserService from '../../../services/UserService'
import useLocalStorage from 'use-local-storage'
import { initColors, initIcons } from '../../../utils/data'
import { imageBase } from '../../../services/http-common'
import { useTranslation } from 'react-i18next'
import CreateCategoryModal from './create-category-modal/CreateCategoryModal'
import AddProcessModal from './add-process-modal/AddProcessModal'
import { useQueryClient } from '@tanstack/react-query'
import { useAddNewProcess } from '../../../repositories/settings/processes/processes/mutations/add-new-process.repository'

const Processes: FC = () => {
  const { org } = useParams()
  const { t } = useTranslation()
  const [addProcessOpen, setAddProcessOpen] = useState(false)
  const [createCategoryOpen, setCreateCategoryOpen] = useState(false)
  const [processDefinitions, setProcessDefinitions] = useState<ProcessDefinitionResponse[]>([])
  const [search, setSearch] = useState('')
  const [categoryLoading, setCategoryLoading] = useState(false)
  const [pendingSearch, setPendingSearch] = useState('')
  const [hideDeactivatedVersions, setHideDeactivatedVersions] = useState(
    JSON.parse(localStorage.getItem('hideDeactivated') !== null ? localStorage.getItem('hideDeactivated')! : 'false'),
  )
  const [loading, setLoading] = useState(true)
  const { mutate: addNewProcessMutation, isPending: isAddingProces } = useAddNewProcess()
  const [colors, setColors] = useLocalStorage('colors', initColors)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [icons /*, setIcons*/] = useLocalStorage('icons', initIcons)
  const [users, setUsers] = useState([])

  useEffect(() => {
    localStorage.setItem('hideDeactivated', JSON.stringify(hideDeactivatedVersions))
  }, [hideDeactivatedVersions])

  const queryClient = useQueryClient()

  const refreshData = useCallback(
    (showSpinner = true) => {
      if (showSpinner) setLoading(true)
      if (!org) return
      ProcessDefinitionsSettings.list(org, {
        versions: true,
        search: search,
        hideDeactivatedVersions,
      })
        .then((response) => {
          console.log(response)

          response.forEach((a) => {
            if ('leaves' in a) {
              a.leaves.sort((ac, bc) => ac.position - bc.position)
            }
          })

          const sorted = response.sort((a, b) => {
            return a.position - b.position
          })
          console.log(sorted)
          setProcessDefinitions(sorted)
          queryClient.invalidateQueries({ queryKey: ['side-menu'] })
          if (showSpinner) setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    },
    [org, search, hideDeactivatedVersions, setProcessDefinitions, queryClient],
  )
  useEffect(() => {
    if (!org) return
    refreshData()
    UserService.listAllUsersInOrg(org).then((res) => {
      setUsers(res.users.map((item) => ({ ...item })))
    })
  }, [org, refreshData])

  const handleAddProcess = (body: any) => {
    if (!org) return
    addNewProcessMutation(
      { body: body },
      {
        onSuccess: () => {
          setAddProcessOpen(false)
          refreshData()
        },
      },
    )
  }

  const handleCategoryCreate = (icon: string, name: string) => {
    if (!org) return
    const body = {
      icon,
      name,
    }
    setCategoryLoading(true)
    ProcessDefinitionsSettings.createCategory(org, body)
      .then(() => {
        setCreateCategoryOpen(false)
        setCategoryLoading(false)
        refreshData()
      })
      .catch(() => setCategoryLoading(false))
  }

  const handleCheckFile = async (base64: string): Promise<any> => {
    if (!org) return

    const response = await ProcessDefinitionsSettings.exists(org, { deploymentPackage: base64 })
    if (!response) {
      return undefined
    }

    return response
  }

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      setSearch(pendingSearch)
    }, 500)

    return () => clearTimeout(delayTyping)
  }, [pendingSearch])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  return (
    <div className='h-full flex flex-col'>
      {/* HEADER */}
      <div className='flex-none px-6 py-7 border-b border-gray-300 flex justify-start items-center'>
        <div>
          <p className='text-sm text-slate-400 mb-2'>S-Process</p>
          <p className='text-xl'>{t('Settings.processes')}</p>
        </div>
        <div className='flex ml-16 h-full items-end gap-x-4'>
          <Button
            className='border border-blue-500 text-blue-500 flex items-center gap-x-2 leading-4'
            onClick={() => setCreateCategoryOpen(true)}
          >
            <PlusIcon className='w-4 h-4' />
            {t('Settings.category')}
          </Button>
          <Button
            className='border border-blue-500 text-blue-500 flex items-center gap-x-2 leading-4'
            onClick={() => setAddProcessOpen(true)}
          >
            <PlusIcon className='w-4 h-4' />
            {t('Settings.process')}
          </Button>
          {(processDefinitions.length > 0 || (processDefinitions.length === 0 && search !== '')) && (
            <Input
              id='process-search'
              classes='border border-gray-300 rounded w-full h-full'
              classes2='!py-0 !h-full'
              mainClasses='!w-[280px] !h-[33.6px]'
              placeholder='Process'
              type='search'
              value={pendingSearch}
              onChange={(e) => setPendingSearch(e.target.value)}
            />
          )}
          <Button className='!px-0' onClick={() => setHideDeactivatedVersions((old) => !old)}>
            {!hideDeactivatedVersions ? <EyeIcon className='w-5 h-5' /> : <EyeSlashIcon className='w-5 h-5' />}
            {t('Settings.disabledVersion')}
          </Button>
        </div>
      </div>
      <div className='px-8 py-7 grow overflow-auto flex justify-between items-start'>
        <div className='w-full max-w-4xl relative'>
          {!loading ? (
            processDefinitions.length > 0 ? (
              <ProcessesConfiguration
                refreshData={refreshData}
                processDefinitions={processDefinitions}
                handleCheckFile={handleCheckFile}
              />
            ) : (
              <p className='text-slate-500'>{t('Settings.noProcesses')}</p>
            )
          ) : (
            <div className='w-full flex justify-center top-1 inset-x-auto absolute'>
              <SpinnerIcon className='w-8 h-8 text-sky-500' />
            </div>
          )}
        </div>
      </div>
      {addProcessOpen && (
        <AddProcessModal
          open={addProcessOpen}
          isLoading={isAddingProces}
          options={users.map((x: any) => ({ ...x, value: x.name, icon: `${imageBase}/${x.objectId}/76` }))}
          setOpen={setAddProcessOpen}
          onAdd={handleAddProcess}
          checkFile={handleCheckFile}
          icons={icons}
          colors={colors}
          setColors={setColors}
        />
      )}
      {createCategoryOpen && (
        <CreateCategoryModal
          open={createCategoryOpen}
          setOpen={setCreateCategoryOpen}
          onCreate={handleCategoryCreate}
          loading={categoryLoading}
          icons={icons}
        />
      )}
    </div>
  )
}

export default Processes
