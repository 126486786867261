import {
  Button,
  DatePicker,
  Option,
  RestartIcon,
  Select,
  SpinnerIcon,
  AssigneeCombobox,
  DataField,
} from '@sistemiv/s-components'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import IncidentReportWrapper from './incident-report/IncidentReportWrapper'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { PriorityIcon } from '@sistemiv/s-components/dist/esm/components/input/Input'
import dayjs from 'dayjs'
import { Priority, formatPriorityRange, getFilteredPriorities } from '../../../utils/priorityUtils'
import { useTranslation } from 'react-i18next'
import { Incident } from '../../../models/ProcessInstance'
import { useIsMobile } from '../../../context/ResponsiveContextProvider'
import { useParams } from 'react-router-dom'
import useAssigneeOptions from '../../../hooks/useAssigneeOptions'

export const mapAssigneTabToType = (value: string) => {
  switch (value) {
    case 'Grpups':
      return 'Group'
    case 'Users':
      return 'User'
    case 'Org chart':
      return 'OrganizationNode'
    default:
      return 'User'
  }
}

type BottomInfoPreviewProps = {
  activityName: string
  dueDate: Date
  assignee: string
  priority: number
  incident?: Incident
  onRestartIncident?(): void
  taskRestarting?: boolean
  onEdit?: (actionString: string, body: any, taskId: string) => void
  activityId: string
  activityType: string
  status?: string
}
const BottomInfoPreview: FC<BottomInfoPreviewProps> = ({
  assignee,
  dueDate,
  activityName,
  priority,
  incident,
  taskRestarting,
  onRestartIncident,
  onEdit,
  activityId,
  activityType,
  status,
}) => {
  const [priorityValue, setPriorityValue] = useState<Priority>(formatPriorityRange(priority))
  const [dateValue, setDateValue] = useState<Date | null>(dueDate ? dayjs(dueDate).toDate() : null)
  const [assigneeValue, setAssigneeValue] = useState<any>(null)
  const { t } = useTranslation()
  const { org } = useParams()
  const { users, groups, orgs, fetchData: fetchAssigneeOptions } = useAssigneeOptions(org)
  const priorities = getFilteredPriorities(priorityValue.value)
  const now = useMemo(() => new Date(), [])
  const bottomRef = useRef(null)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (!org) return
    fetchAssigneeOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org])

  useEffect(() => {
    //scroll down to focus component
    if (bottomRef.current && !isMobile) {
      //@ts-ignore
      bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [bottomRef, activityName, isMobile])

  useEffect(() => {
    if (users && orgs && groups) {
      const selectedAssignee = [...users, ...groups, ...orgs].find((o: any) => o.id === assignee)
      setAssigneeValue(selectedAssignee)
    }
  }, [orgs, groups, users, assignee])

  useEffect(() => setPriorityValue(formatPriorityRange(priority)), [priority])
  useEffect(() => setDateValue(dueDate ? dayjs(dueDate).toDate() : null), [dueDate])

  return (
    <div className={`flex flex-col ${isMobile ? '' : 'mb-2'}`} ref={bottomRef}>
      {taskRestarting ? (
        <div className='bg-[#6275A5] text-white py-2 px-3 mb-4 flex gap-x-8 rounded'>
          <SpinnerIcon className='w-6 h-6 text-sky-500' />
          <p>{t('ProcessInstance.restartingFailed')}</p>
        </div>
      ) : (
        <div className={`flex gap-x-20 ${isMobile ? 'flex-col' : 'flex-row'}`}>
          <IncidentReportWrapper label={t('ProcessInstance.activity')}>
            <div className='flex flex-row gap-1.5 items-center'>
              <div className='rounded bg-[#DEE1FC] flex items-start p-0.5 px-2 min-w-fit'>{activityName}</div>
              {incident && <ExclamationCircleIcon className='w-5 h-5 text-red-400' />}
              {incident && incident.retriable && status === 'Active' && (
                <Button onClick={() => onRestartIncident?.()} className='!p-0'>
                  <RestartIcon className='w-5 h-5 text-gray-400 hover:text-gray-700' />
                </Button>
              )}
            </div>
          </IncidentReportWrapper>
          {activityType === 'userTask' && (
            <>
              {onEdit && !incident && (
                <IncidentReportWrapper label={t('ProcessInstance.taskDueDate')}>
                  {status && status !== 'Active' ? (
                    <div className='flex justify-start items-center gap-x-3'>
                      {dateValue && dayjs(dateValue).isValid() ? dayjs(dateValue).format('DD MMM YYYY') : 'Unknown'}
                    </div>
                  ) : (
                    <DatePicker
                      value={dateValue}
                      editMode={true}
                      inputClassName='!px-0'
                      onChange={(date) => {
                        setDateValue(date)
                        onEdit('due-date', { dueDate: date }, activityId)
                      }}
                      topMenu={!isMobile}
                      min={now}
                    />
                  )}
                </IncidentReportWrapper>
              )}
              {onEdit && !incident && (
                <IncidentReportWrapper label={t('ProcessInstance.taskAssignee')}>
                  {status && status !== 'Active' ? (
                    <div
                      className={`flex justify-start items-center gap-x-3 w-full  pr-9 whitespace-nowrap overflow-hidden h-full`}
                    >
                      {assigneeValue ? (
                        <DataField field={assigneeValue} className='[&>div>svg]:w-6 [&>div>svg]:h-6' />
                      ) : (
                        <div className={`text-black`}>Unknown</div>
                      )}
                    </div>
                  ) : (
                    <AssigneeCombobox
                      options={[...users, ...groups, ...orgs]}
                      selected={assigneeValue}
                      type='edit'
                      isMobile={isMobile}
                      onChange={(value) => {
                        console.log(value)
                        onEdit(
                          'assignee',
                          { assigneeId: value.id, assigneeType: mapAssigneTabToType(value.tab) },
                          activityId,
                        )
                        setAssigneeValue(value)
                      }}
                    />
                  )}
                </IncidentReportWrapper>
              )}
              {!incident && onEdit && (
                <IncidentReportWrapper label={t('ProcessInstance.taskPriority')}>
                  {status && status !== 'Active' ? (
                    <div className='flex justify-start items-center gap-x-3'>
                      <PriorityIcon priority={priorityValue.label} />
                      {priorityValue.label}
                    </div>
                  ) : (
                    <Select
                      type='priority'
                      value={priorityValue}
                      topMenu={!isMobile}
                      onChange={(e) => {
                        setPriorityValue(e)
                        onEdit('priority', { priority: e.value }, activityId)
                      }}
                      editMode={true}
                    >
                      {priorities
                        .filter((p) => p.value !== priorityValue.value)
                        .map((p) => (
                          <Option key={p.value} value={p}>
                            <div className='flex justify-start items-center gap-x-3'>
                              <PriorityIcon priority={p.label} />
                              {p.label}
                            </div>
                          </Option>
                        ))}
                    </Select>
                  )}
                </IncidentReportWrapper>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default BottomInfoPreview
