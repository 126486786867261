import { Combobox, Dialog, Transition } from '@headlessui/react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { useTranslation } from 'react-i18next'
import { DatePicker, ModalDialog } from '@sistemiv/s-components'
import { PriorityIcon } from '@sistemiv/s-components/dist/esm/components/input/Input'
import AssigneeComboboxBulkAction from './AssigneeComboboxBulkAction'
type BulkActionMobileModalProps = {
  isOpen: boolean
  setOpen: DispatchSetAction<boolean>
  action: string
  onConfirm?: (value: any, prefix: any, action: string) => void
  assignees?: TableField[]
  numberOfSelected?: number
}

const BulkActionMobileModal: FC<BulkActionMobileModalProps> = ({
  isOpen,
  setOpen,
  action,
  onConfirm,
  assignees,
  numberOfSelected,
}) => {
  const [value, setValue] = useState<any>(undefined)
  const { t } = useTranslation()
  const now = useMemo(() => new Date(), [])
  /* eslint-disable */
  const [prefix, setPrefix] = useState('')
  /* eslint-enable */
  const priorities = [
    { value: 'Highest', label: 'Highest' },
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' },
    { value: 'Lowest', label: 'Lowest' },
  ]

  const title = useMemo(() => {
    return action.includes('assignee')
      ? t('ProcessInstance.bulkUpdate.editAssignee')
      : action.includes('due-date')
      ? t('ProcessInstance.bulkUpdate.editDueDate')
      : action.includes('priority')
      ? t('ProcessInstance.bulkUpdate.editPriority')
      : action.includes('complete')
      ? t('ProcessInstance.bulkUpdate.completeTask')
      : action.includes('retry')
      ? t('ProcessInstance.bulkUpdate.restartFailed')
      : // : action === 'linked-processPI'
        // ? 'Add linked process'
        // : action === 'commentUT'
        // ? 'Add comment'
        ''
  }, [action, t])

  useEffect(() => {
    if (isOpen) {
      //ako je otvoren, history je vec postavljen

      const handlePopState = () => setOpen(false)
      window.addEventListener('popstate', handlePopState)

      return () => {
        window.removeEventListener('popstate', handlePopState)
      }
    }

    return undefined
  }, [isOpen, setOpen])

  const closeModal = ({ clearHistroy = true } = {}) => {
    setOpen(false)

    if (clearHistroy && window.history.state?.modalOpen) {
      history.back()
    }
  }

  if (action.includes('due-date')) {
    return (
      <ModalDialog isOpen={isOpen} setOpen={setOpen}>
        <div>
          <DatePicker
            className=''
            value={value as Date}
            onChange={(date) => setValue(date)}
            min={now}
            isMobile={true}
          />

          <div className='flex justify-center pt-4 gap-x-3'>
            <button className='flex-1 rounded-full px-4 py-2 text-sm' onClick={() => closeModal()}>
              {t('Common.cancel')}
            </button>
            <button
              disabled={action.includes('retry') ? false : !value}
              className='flex-1 rounded-full py-2 text-sm !px-6 bg-sky-500 text-white disabled:bg-slate-200 disabled:text-slate-500'
              onClick={() => {
                onConfirm && onConfirm(value, prefix, action)
              }}
            >
              OK
            </button>
          </div>

          {numberOfSelected && numberOfSelected > 1 && (
            <div className='flex flex-row items-center mt-2 gap-x-1'>
              <ExclamationCircleIcon className='w-5 h-5 stroke-orange-400' />
              <span className='text-sm text-gray-700'>{`${numberOfSelected} ${t(
                'ProcessInstance.bulkUpdate.updatingInstancesAlert',
              )}`}</span>
            </div>
          )}
        </div>
      </ModalDialog>
    )
  } else {
    return (
      <Transition.Root show={isOpen} as={React.Fragment}>
        <Dialog
          // open={isOpen} ovo sam iskomentarisao jer zeza animaciju
          onClose={() => {
            console.log('')
          }}
          className='relative z-50'
          as={'div'}
        >
          <Transition.Child
            as={React.Fragment}
            enter='transition-opacity duration-80'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity duration-80'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' aria-hidden='true' />
          </Transition.Child>
          <div className='fixed inset-x-0 bottom-0 flex items-start justify-center'>
            <Transition.Child
              as={React.Fragment}
              enter='transition-transform duration-80 ease-out'
              enterFrom='transform translate-y-full'
              enterTo='transform translate-y-0'
              leave='transition-transform duration-80 ease-in'
              leaveFrom='transform translate-y-0'
              leaveTo='transform translate-y-full'
            >
              <Dialog.Panel className={`bg-white py-4 px-3 rounded-t-2xl shadow-lg w-full max-h-[50vh]`}>
                <div className='w-12 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
                <div className='flex flex-col w-full'>
                  <div className='flex flex-row pb-2 items-center justify-between'>
                    <p className='text-xs font-medium whitespace-nowrap'>{title}</p>
                  </div>
                  <div className={`w-full max-h-[calc(50vh-50px)] overflow-y-auto overflow-x-hidden pb-6`}>
                    {action.includes('assignee') && assignees ? (
                      <AssigneeComboboxBulkAction
                        options={assignees}
                        selected={value}
                        onChange={(value) => setValue(value)}
                      />
                    ) : action.includes('priority') ? (
                      <Combobox value={value} onChange={(e) => setValue(e)}>
                        <div className='relative'>
                          <Combobox.Options
                            className='max-h-60 w-full py-1 text-base focus:outline-none sm:text-sm'
                            static
                          >
                            {priorities.map((priority) => (
                              <Combobox.Option
                                key={`option-${priority.value}`}
                                value={priority.value}
                                className={
                                  'relative cursor-pointer select-none py-2 px-4 text-gray-900' +
                                  (value === priority.value ? ' bg-sky-100 rounded-lg' : '')
                                }
                              >
                                <div className='flex justify-start items-center gap-x-3'>
                                  <PriorityIcon priority={priority.value} />
                                  {priority.label}
                                </div>
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        </div>
                      </Combobox>
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div className='flex justify-center pt-4 gap-x-3'>
                    <button className='flex-1 rounded-full px-4 py-2 text-sm' onClick={() => closeModal()}>
                      {t('Common.cancel')}
                    </button>
                    <button
                      disabled={action.includes('retry') ? false : !value}
                      className='flex-1 rounded-full py-2 text-sm !px-6 bg-sky-500 text-white disabled:bg-slate-200 disabled:text-slate-500'
                      onClick={() => {
                        onConfirm && onConfirm(value, prefix, action)
                      }}
                    >
                      OK
                    </button>
                  </div>

                  {numberOfSelected && numberOfSelected > 1 && (
                    <div className='flex flex-row items-center mt-2 gap-x-1'>
                      <ExclamationCircleIcon className='w-5 h-5 stroke-orange-400' />
                      <span className='text-sm text-gray-700'>{`${numberOfSelected} ${t(
                        'updatingInstancesAlert',
                      )}`}</span>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
}

export default BulkActionMobileModal
