import React, { FC } from 'react'
import { ChevronRightIcon, ChevronLeftIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Button, ImgWrapper, LinkToDashboardIcon, LinkToIcon, ClockIcon } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { useGetApplications } from '../../repositories/user/user-applications.repository'
import { useIsMobile } from '../../context/ResponsiveContextProvider'
import { useNavigate } from 'react-router-dom'

type UserTaskHeaderProps = {
  taskName: string
  selectedTask: any
  currentTask: number
  totalTasks: number
  onPreviousClicked?: (e: any) => void
  onNextClicked?: (e: any) => void
  onGoToProcessInstanceClicked?: (e: any) => void
  onGoToHomeDashboardClicked?: (e: any) => void
  onClaimTask: () => void
  claiming?: boolean
  unclaimed?: boolean
  assigneeImage?: string
  userFirstName?: string
}

const UserTaskHeader: FC<UserTaskHeaderProps> = ({
  taskName,
  currentTask,
  selectedTask,
  totalTasks,
  onNextClicked,
  onPreviousClicked,
  onGoToProcessInstanceClicked,
  onGoToHomeDashboardClicked,
  onClaimTask,
  claiming = false,
  unclaimed,
  assigneeImage,
  userFirstName,
}) => {
  const { t } = useTranslation()
  const { data: applications } = useGetApplications()
  const hasManagePermission = !!applications
    ?.find((app) => app.code === 's-process')
    ?.permissions?.find((perm) => perm === 'manage')
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const mobileContent = (
    <>
      {selectedTask?.processInstanceStatus === 'Paused' && (
        <div className='flex items-center justify-between px-4 py-1.5 bg-[#FFDCB2] shadow-[inset_4px_0px_0px_0px_#FF6534]'>
          <p className='font-open-sans text-[14px] leading-[22px] tracking-[-0.03em] align-middle text-[#FE4C14] font-normal'>
            {`The process instance related to this user task is paused. You can continue with this task when the process instance is resumed.`}
          </p>
        </div>
      )}

      <div className='flex justify-between items-center py-3 w-full overflow-hidden' data-testid='user-task-header'>
        {/* Left Section: Arrow and Task Name */}
        <div className='flex items-center gap-x-3 flex-grow overflow-hidden'>
          <button onClick={() => navigate(-1)} className='rounded-full hover:bg-sky-50 p-2 flex-shrink-0'>
            <ArrowLeftIcon className='w-6 h-6 text-gray-400' />
          </button>
          <div className='text-lg font-normal whitespace-nowrap overflow-hidden text-ellipsis'>{taskName}</div>
        </div>

        {/* Right Section: Claim Button or Assignee Image */}
        <div className='px-2 flex-shrink-0 min-w-fit'>
          {unclaimed ? (
            <Button
              className='bg-sky-500 text-white max-w-full'
              onClick={() => {
                onClaimTask?.()
              }}
              loading={claiming}
              disabled={claiming}
            >
              {t('UserTasks.claim')}
            </Button>
          ) : (
            <ImgWrapper
              className='w-6 h-6 rounded-full'
              src={assigneeImage}
              onErrorComponent={
                <p className='w-6 h-6 rounded-full border border-gray-300 text-white font-semibold bg-[#7B87AE] flex items-center justify-center text-lg'>
                  {userFirstName?.charAt(0)?.toUpperCase()}
                </p>
              }
            />
          )}
        </div>
      </div>
    </>
  )

  const desktopContent = (
    <div>
      {selectedTask?.processInstanceStatus === 'Paused' && (
        <div className='flex items-center justify-between px-6 py-3 bg-[#FFDCB2] shadow-[inset_4px_0px_0px_0px_#FF6534]'>
          <p className='font-open-sans text-[14px] leading-[22px] tracking-[-0.03em] align-middle text-[#FE4C14] font-normal'>
            {`The process instance related to this user task is paused. You can continue with this task when the process instance is resumed.`}
          </p>
        </div>
      )}

      <div className='flex justify-between items-center px-8 py-5' data-testid='user-task-header'>
        <div className='flex items-center gap-x-3 font-normal text-base text-slate-700'>
          <ClockIcon className='w-5 h-5 fill-gray-500 flex-none' />
          <div className='text-sm'>/</div>
          <div className='max-w-sm whitespace-nowrap overflow-hidden text-ellipsis'>{taskName}</div>
          <div className='flex items-center gap-x-4'>
            {hasManagePermission && (
              <a className='cursor-pointer' onClick={onGoToProcessInstanceClicked} data-testid='process-instance-link'>
                <LinkToIcon className='w-4 h-4 fill-blue-500' />
              </a>
            )}
            {unclaimed && (
              <Button
                className='bg-sky-500 text-white ml-10'
                onClick={() => {
                  onClaimTask?.()
                }}
                loading={claiming}
                disabled={claiming}
              >
                {t('UserTasks.claim')}
              </Button>
            )}
            {!unclaimed && (
              <ImgWrapper
                className='w-7 h-7 rounded-full'
                src={assigneeImage}
                onErrorComponent={
                  <p className='w-7 h-7 rounded-full border border-gray-300 text-white font-semibold bg-[#7B87AE] flex items-center justify-center text-lg'>
                    {userFirstName?.charAt(0)?.toUpperCase()}
                  </p>
                }
              />
            )}
          </div>
        </div>
        <div className='flex items-center gap-x-10'>
          <div className='flex items-center gap-x-3'>
            <button
              data-testid='previous-button'
              disabled={currentTask <= 1}
              className='text-gray-500 disabled:text-gray-300'
              onClick={onPreviousClicked}
            >
              <ChevronLeftIcon className='w-3 h-3 stroke-2' />
            </button>
            <div className='text-sm text-slate-700'>{`${currentTask} ${t('UserTasks.of')} ${totalTasks}`}</div>
            <button
              data-testid='next-button'
              disabled={currentTask >= totalTasks}
              className='text-gray-500 disabled:text-gray-300'
              onClick={onNextClicked}
            >
              <ChevronRightIcon className='w-3 h-3 stroke-2' />
            </button>
          </div>
          <div className='flex items-center gap-x-3'>
            <a className='cursor-pointer' onClick={onGoToHomeDashboardClicked} data-testid='home-dashboard-button'>
              <LinkToDashboardIcon className='w-5 h-5 fill-slate-700' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )

  return isMobile ? mobileContent : desktopContent
}

export default UserTaskHeader
