import { HomeIcon, ClockIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import {
  Base64SVG,
  ExpandableSideMenuItem,
  SideMenuItem,
  SideMenu,
  SideMenuParentArrowIcon,
  MobileModalWithButton,
} from '@sistemiv/s-components'
import React, { FC, useEffect, useState } from 'react'
import classNames from '../../classNames'
import SettingsMenuItems from './SettingsMenuItems'
import SideMenuProcessItem from './SideMenuProcessItem'
import { useNavigate, useParams } from 'react-router-dom'
import { useSideMenu } from '../../repositories/process-instances/side-menu.repository'
import { useGetApplications } from '../../repositories/user/user-applications.repository'
import { useTranslation } from 'react-i18next'
import { useIsMobile, useNavigationContext } from '../../context/ResponsiveContextProvider'
import { Transition } from '@headlessui/react'

const SideNav: FC<{ isSideOpen: boolean }> = ({ isSideOpen }) => {
  const [process, setProcess] = useState<any>()
  const [expandedSubmenus, setExpandedSubmenus] = useState<string[]>([])
  const navigate = useNavigate()
  const { data: applications } = useGetApplications()
  const { org, nav, type } = useParams()
  const { t } = useTranslation()
  const runSideMenu = !!applications
    ?.find((app) => app.code === 's-process')
    ?.permissions?.find((perm) => perm === 'manage')
  const { data: sideMenuItems } = useSideMenu({ enabled: runSideMenu })
  const isMobile = useIsMobile()
  const { hideBottomNavigation } = useNavigationContext()
  const handleOnSubmenuItemClick = (submenuItem: string) => {
    if (!expandedSubmenus.includes(submenuItem)) {
      setExpandedSubmenus((prev) => {
        return [...prev, submenuItem]
      })
      return
    }
    setExpandedSubmenus((prev) => {
      return [...prev].filter((v) => v !== submenuItem)
    })
  }
  const checkIsSubmenuSelected = (process: any) => {
    return nav &&
      type &&
      nav === 'process-instance' &&
      !expandedSubmenus.includes(process.translationKey) &&
      process.children?.find((c) => c.id === type)
      ? true
      : false
  }

  useEffect(() => {
    if (nav != 'process-instance' || sideMenuItems?.length === 0 || !type) setProcess(null)
  }, [org, nav, type, sideMenuItems])

  if (isMobile && sideMenuItems?.length < 1) return <></>
  return (
    <Transition
      show={!hideBottomNavigation}
      as='div'
      enter='transition-opacity duration-80 ease-in-out'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-80 ease-in-out'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
      className={'z-50'}
    >
      <SideMenu isOpen={isSideOpen} isMobile={isMobile}>
        {!isMobile && (
          <SideMenuItem
            onClick={() => navigate(`/${org}`)}
            classes={classNames(
              isMobile
                ? nav === undefined
                  ? 'text-blue-500 hover:bg-white'
                  : 'text-black'
                : nav === undefined
                ? 'bg-blue-500 text-white hover:!bg-blue-500 hover:!text-white'
                : 'text-black',
              isMobile
                ? 'flex flex-col items-center'
                : isSideOpen
                ? 'gap-x-3 px-9 py-3.5'
                : 'justify-center items-center py-3.5',
            )}
          >
            <HomeIcon className={classNames('w-5 h-5', isMobile ? 'w-6 h-6 mb-1' : '')} />
            {isMobile ? (
              <span className={`text-xs`}>{t('SideNav.home')}</span> // Text color determined by the parent class
            ) : (
              isSideOpen && t('SideNav.home')
            )}
          </SideMenuItem>
        )}
        <SideMenuItem
          classes={classNames(
            isMobile
              ? nav === 'tasks'
                ? 'text-blue-500 hover:bg-white'
                : 'text-black'
              : nav === 'tasks'
              ? 'bg-blue-500 text-white hover:!bg-blue-500 hover:!text-white'
              : 'text-black',
            isMobile
              ? 'flex flex-col items-center'
              : isSideOpen
              ? 'gap-x-3 px-9 py-3.5'
              : 'justify-center items-center py-3.5',
          )}
          onClick={() => navigate(`/${org}/tasks`)}
        >
          <ClockIcon className={classNames('w-5 h-5', isMobile ? 'w-6 h-6 mb-1' : '')} />
          {isMobile ? <span className={`text-xs`}>{t('SideNav.tasks')}</span> : isSideOpen && t('SideNav.tasks')}
        </SideMenuItem>
        {isMobile && (
          <SideMenuItem classes={'flex flex-col items-center hover:bg-white'}>
            <MobileModalWithButton
              buttonContent={
                process?.name ? (
                  <>
                    <Base64SVG
                      base64image={process?.icon ?? ''}
                      className='[&>svg]:w-6 [&>svg]:h-6 [&>svg>*]:!fill-blue-500 mb-1'
                    />
                    <span className='whitespace-nowrap overflow-hidden text-ellipsis text-xs'>{process.name}</span>
                  </>
                ) : (
                  <>
                    <ClipboardDocumentListIcon className={classNames('w-6 h-6 mb-1')} />
                    <span className={`text-xs`}>{t('SideNav.processes')}</span>
                  </>
                )
              }
              className={classNames(
                'flex flex-col items-center',
                nav === 'process-instance' ? 'text-blue-500 hover:bg-white' : 'text-black',
              )}
              leftLabel={t('ProcessInstance.switchProcess') as string}
            >
              {({ closeModal }) => (
                <>
                  {sideMenuItems &&
                    sideMenuItems
                      .sort((a, b) => a.position - b.position)
                      .map((process, i) => {
                        return process.type === 'CategoryItem' ? (
                          process.children && process.children.length > 0 && (
                            <div key={`process-${i}`} className='w-full'>
                              <div
                                key={`process-${process.name}`}
                                className={classNames('flex flex-row items-center w-full py-3 px-4 gap-x-3 relative')}
                                onClick={() => handleOnSubmenuItemClick(process.name)}
                              >
                                <SideMenuParentArrowIcon
                                  className={classNames(
                                    'absolute left-0 w-3 h-3 fill-black',
                                    expandedSubmenus.includes(process.name) ? 'rotate-90' : '',
                                  )}
                                />
                                <Base64SVG
                                  base64image={process.icon}
                                  className={classNames('[&>svg]:w-5 [&>svg]:h-5 [&>svg>*]:fill-black flex-none')}
                                />
                                <p className='whitespace-nowrap overflow-hidden text-ellipsis'>{process.name}</p>
                              </div>

                              <div>
                                {expandedSubmenus.includes(process.name) &&
                                  process.children
                                    ?.sort((a, b) => a.position - b.position)
                                    .map((child) => (
                                      <div key={`child-${child.processDefinitionKey}`}>
                                        <div
                                          className={classNames(
                                            'flex flex-row items-center w-full py-3 px-2 gap-x-3 ml-6',
                                          )}
                                          onClick={() => {
                                            setProcess(child)
                                            navigate(`/${org}/process-instance/${child.processDefinitionKey}`)
                                            closeModal()
                                          }}
                                        >
                                          <Base64SVG
                                            base64image={child.icon ?? ''}
                                            className={classNames(
                                              '[&>svg]:w-5 [&>svg]:h-5 [&>svg>*]:fill-black flex-none',
                                              nav &&
                                                type &&
                                                nav === 'process-instance' &&
                                                type === child.processDefinitionKey
                                                ? '[&>svg>*]:!fill-blue-500 [&>svg>*]:hover:!fill-blue-500'
                                                : '',
                                            )}
                                          />
                                          <p className='whitespace-nowrap overflow-hidden text-ellipsis'>
                                            {child.name}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                              </div>
                            </div>
                          )
                        ) : (
                          <div key={`process-${i}`}>
                            <div
                              className={classNames('flex flex-row items-center w-full py-3 px-4 gap-x-3')}
                              onClick={() => {
                                setProcess(process)
                                navigate(`/${org}/process-instance/${process.processDefinitionKey}`, { replace: true })
                                closeModal({ clearHistroy: false })
                              }}
                            >
                              <Base64SVG
                                base64image={process.icon ?? ''}
                                className={classNames(
                                  '[&>svg]:w-5 [&>svg]:h-5 [&>svg>*]:fill-black flex-none',
                                  nav && type && nav === 'process-instance' && type === process.processDefinitionKey
                                    ? '[&>svg>*]:!fill-blue-500 [&>svg>*]:hover:!fill-blue-500'
                                    : '',
                                )}
                              />
                              <p className='whitespace-nowrap overflow-hidden text-ellipsis'>{process.name}</p>
                            </div>
                          </div>
                        )
                      })}
                </>
              )}
            </MobileModalWithButton>
          </SideMenuItem>
        )}
        {!isMobile &&
          sideMenuItems &&
          sideMenuItems
            .sort((a, b) => a.position - b.position)
            .map((process, i) => {
              return process.type === 'CategoryItem' ? (
                process.children && process.children.length > 0 && (
                  <div key={`process-${i}`}>
                    <ExpandableSideMenuItem
                      key={`process-${process.name}`}
                      className={classNames(
                        'active:bg-blue-500 active:text-white',
                        isSideOpen ? 'gap-x-3 px-9 py-3.5' : 'justify-center items-center py-3.5',
                      )}
                      expandedClasses={expandedSubmenus.includes(process.name) ? 'rotate-90' : ''}
                      selectedClasses={checkIsSubmenuSelected(process) ? '!fill-white hover:!fill-white' : ''}
                      onClick={() => handleOnSubmenuItemClick(process.name)}
                    >
                      <Base64SVG
                        base64image={process.icon}
                        className={classNames(
                          '[&>svg]:w-5 [&>svg]:h-5 flex-none [&>svg>*]:fill-black',
                          checkIsSubmenuSelected(process) ? '[&>svg>*]:!fill-white [&>svg>*]:hover:!fill-white' : '',
                        )}
                      />
                      <p className='whitespace-nowrap overflow-hidden text-ellipsis'>{isSideOpen && process.name}</p>
                    </ExpandableSideMenuItem>
                    <div>
                      {expandedSubmenus.includes(process.name) &&
                        process.children
                          ?.sort((a, b) => a.position - b.position)
                          .map((child) => (
                            <SideMenuItem
                              key={`child-${child.processDefinitionKey}`}
                              classes={classNames(
                                isSideOpen ? 'gap-x-3 pl-12 pr-9 py-3.5' : 'justify-center items-center py-3.5',
                                nav && type && nav === 'process-instance' && type === child.processDefinitionKey
                                  ? 'bg-blue-500 text-white hover:!bg-blue-500 hover:!text-white'
                                  : '',
                              )}
                              onClick={() => navigate(`/${org}/process-instance/${child.processDefinitionKey}`)}
                            >
                              <Base64SVG
                                base64image={child.icon ?? ''}
                                className={classNames(
                                  '[&>svg]:w-5 [&>svg]:h-5 [&>svg>*]:fill-black flex-none',
                                  nav && type && nav === 'process-instance' && type === child.processDefinitionKey
                                    ? '[&>svg>*]:!fill-white [&>svg>*]:hover:!fill-white'
                                    : '',
                                )}
                              />
                              <p className='whitespace-nowrap overflow-hidden text-ellipsis'>
                                {isSideOpen && child.name}
                              </p>
                            </SideMenuItem>
                          ))}
                    </div>
                  </div>
                )
              ) : (
                <SideMenuProcessItem key={`process-${i}`} process={process} isSideOpen={isSideOpen} />
              )
            })}
        {!isMobile &&
          applications?.find((app) => app.code === 's-process')?.permissions?.find((perm) => perm === 'design') && (
            <SettingsMenuItems isSideOpen={isSideOpen} />
          )}
      </SideMenu>
    </Transition>
  )
}

export default SideNav
