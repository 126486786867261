import React, { createContext, useState, useEffect, useContext, FC, PropsWithChildren } from 'react'

// Contexts for separating concerns
const IsMobileContext = createContext<boolean>(false)
const NavigationContext = createContext<{
  hideTopNavigation: boolean
  hideBottomNavigation: boolean
  hideNavigations: (bool: boolean, lock?: boolean) => void
  setNavigationLock: React.Dispatch<React.SetStateAction<boolean>>
  setHideBottomNavigation: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

const ResponsiveContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)
  const [hideTopNavigation, setHideTopNavigation] = useState<boolean>(false)
  const [hideBottomNavigation, setHideBottomNavigation] = useState<boolean>(false)
  const [navigationLock, setNavigationLock] = useState<boolean>(false)

  // Handle window resize for `isMobile`
  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout

    const handleResize = () => {
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 768)
      }, 200)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      clearTimeout(resizeTimeout)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Handle navigation state when `isMobile` changes
  useEffect(() => {
    if (!isMobile) {
      setNavigationLock(true)
      setHideTopNavigation(false)
      setHideBottomNavigation(false)
    } else {
      setNavigationLock(false)
    }
  }, [isMobile])

  const hideNavigations = (bool: boolean, lock?: boolean) => {
    if ((lock !== undefined && lock == false) || !navigationLock) {
      setHideTopNavigation(bool)
      setHideBottomNavigation(bool)
    }
  }

  return (
    <IsMobileContext.Provider value={isMobile}>
      <NavigationContext.Provider
        value={{
          hideTopNavigation,
          hideBottomNavigation,
          hideNavigations,
          setNavigationLock,
          setHideBottomNavigation,
        }}
      >
        {children}
      </NavigationContext.Provider>
    </IsMobileContext.Provider>
  )
}

export default ResponsiveContextProvider

// Custom hooks for consuming individual contexts
export const useIsMobile = () => useContext(IsMobileContext)

export const useNavigationContext = () => {
  const context = useContext(NavigationContext)
  if (!context) {
    throw new Error('useNavigationContext must be used within a ResponsiveContextProvider')
  }
  return context
}
