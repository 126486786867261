import { Input, Option, Select, SpinnerIcon, AssigneeCombobox } from '@sistemiv/s-components'
import React, { FC, useEffect } from 'react'
import classNames from '../../../../classNames'
import { useParams, useSearchParams } from 'react-router-dom'
import { UserTask } from '../../../../models/ProcessDefinitionSettings'
import { useUserTaskSettings } from '../../../../repositories/settings/processes/user-tasks/user-tasks-get.repository'
import { useChangeAssigneeCandidate } from '../../../../repositories/settings/processes/user-tasks/mutations/change-assignee-candidate.mutation'
import { useChangeAllowBulk } from '../../../../repositories/settings/processes/user-tasks/mutations/change-allow-bulk.mutation'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import useAssigneeOptions from '../../../../hooks/useAssigneeOptions'

const UserTasksTab: FC = () => {
  const { t } = useTranslation()
  const { org } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { users, groups, orgs, fetchData: fetchAssigneeOptions } = useAssigneeOptions(org)

  const queryClient = useQueryClient()

  const {
    data: tasks,
    isLoading,
    isFetching,
  } = useUserTaskSettings({ organization: org, versionId: searchParams.get('version') ?? undefined })

  const { mutate: updateCandidate, isPending: isChangingCandidate } = useChangeAssigneeCandidate()
  const { mutate: updateAllowBulk, isPending: isChangingBulk } = useChangeAllowBulk()

  const loading = isLoading || isFetching || isChangingCandidate || isChangingBulk

  const defaultAssigneeValues = {
    InstanceStarter: t('Settings.userTasks.defaultAssigneeValues.InstanceStarter'),
    InstanceStarterNode: t('Settings.userTasks.defaultAssigneeValues.InstanceStarterNode'),
    SelectedIdentificationEntity: t('Settings.userTasks.defaultAssigneeValues.SelectedIdentificationEntity'),
  }

  useEffect(() => {
    if (!org) return
    fetchAssigneeOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org, searchParams, setSearchParams])

  const body = (
    <div className='relative'>
      <div className='relative w-full max-w-3xl'>
        {loading && (
          <div className='flex justify-center top-1 inset-x-auto absolute w-full'>
            <SpinnerIcon className='w-8 h-8 text-sky-500' />
          </div>
        )}
        <table className={`${loading ? 'opacity-50' : 'opacity-100'} table-auto w-full text-sm`}>
          <thead>
            <tr className='border-b border-gray-300'>
              <th className='text-left text-slate-500 font-normal px-2 py-1'>{t('Settings.userTasks.task')}</th>
              <th className='text-left text-slate-500 font-normal px-2 py-1'>
                {t('Settings.userTasks.allowBulkComplete')}
              </th>
              <th className='text-left text-slate-500 font-normal px-2 py-1'>
                {t('Settings.userTasks.defaultAssignees')}
              </th>
            </tr>
          </thead>
          <tbody>
            {tasks &&
              tasks?.userTasks?.map((row: UserTask) => {
                return (
                  <tr key={row.id} className='border-b border-gray-300'>
                    <td className='px-2 py-1'>{row.taskName}</td>
                    <td className='px-2 py-1'>
                      <Input
                        type='checkbox'
                        tooltip={row.hasForm ? (t('Settings.userTasks.taskHasForm') as string) : ''}
                        id='check'
                        mainClasses='w-fit'
                        classes='!mr-0'
                        checked={row.allowBulkComplete}
                        disabled={row.hasForm}
                        onChange={(e) => {
                          const versionId = searchParams.get('version')
                          if (!org || !versionId) return
                          updateAllowBulk({ organization: org, versionId, taskId: row.id, value: e.target.checked })
                        }}
                      />
                    </td>
                    <td className='px-2 py-1'>
                      <div
                        className={classNames(
                          row.assigneeCandidateType === 'SelectedIdentificationEntity'
                            ? 'grid grid-cols-2 gap-x-1.5 w-full'
                            : 'flex items-center gap-x-3 w-full',
                        )}
                      >
                        <Select
                          value={
                            defaultAssigneeValues[
                              ['User', 'Group', 'OrganizationNode'].includes(row.assigneeCandidateType)
                                ? 'SelectedIdentificationEntity'
                                : row.assigneeCandidateType
                            ]
                          }
                          onChange={(value) => {
                            if (value !== 'SelectedIdentificationEntity') {
                              const versionId = searchParams.get('version')
                              if (!org || !versionId) return
                              updateCandidate({ organization: org, versionId, taskId: row.id, assigneeType: value })
                            } else {
                              queryClient.setQueryData(
                                ['user-tasks-settings', org, searchParams.get('version')],
                                (old: any) => {
                                  console.log(old)
                                  return {
                                    ...old,
                                    userTasks: old.userTasks?.map((v) => {
                                      if (v.id === row.id) {
                                        return {
                                          ...v,
                                          assigneeCandidateType: value,
                                        }
                                      }
                                      return v
                                    }),
                                  }
                                },
                              )
                            }
                          }}
                          wrapperClasses='w-full'
                        >
                          {!tasks.startableByWebhook && (
                            <Option value={'InstanceStarter'}>
                              <p className='whitespace-nowrap select-none'>
                                {defaultAssigneeValues['InstanceStarter']}
                              </p>
                            </Option>
                          )}
                          <Option value={'instanceStarterNode'}>
                            <p className='whitespace-nowrap select-none'>
                              {defaultAssigneeValues['InstanceStarterNode']}
                            </p>
                          </Option>
                          <Option value={'SelectedIdentificationEntity'}>
                            <p className='whitespace-nowrap select-none'>
                              {defaultAssigneeValues['SelectedIdentificationEntity']}
                            </p>
                          </Option>
                        </Select>
                        {['User', 'Group', 'OrganizationNode', 'SelectedIdentificationEntity'].includes(
                          row.assigneeCandidateType,
                        ) && (
                          <AssigneeCombobox
                            options={[...users, ...groups, ...orgs]}
                            selected={[...users, ...groups, ...orgs].find((o: any) => o.id === row.assigneeCandidateId)}
                            onChange={(value) => {
                              if (value !== 'SelectedIdentificationEntity') {
                                const versionId = searchParams.get('version')
                                if (!org || !versionId) return
                                updateCandidate({
                                  organization: org,
                                  versionId,
                                  taskId: row.id,
                                  assigneeType:
                                    value.tab === 'Users'
                                      ? 'User'
                                      : value.tab === 'Groups'
                                      ? 'Group'
                                      : 'OrganizationNode',
                                  assigneeId: value.id,
                                })
                              }
                            }}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )

  return (
    <div className='overflow-hidden h-full flex flex-col'>
      <div className='p-8 overflow-auto grow'>{body}</div>
    </div>
  )
}

export default UserTasksTab
